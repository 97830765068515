import { Component } from '@angular/core'

@Component({
  selector: 'app-introduction',
  template: `
    <div class="content is-normal pt-5">
      <h1 class="title is-2">Before you begin</h1>
      <p>Thank you for providing details on new or updated AI initiatives.</p>

      <h2 class="title is-5">
        Before continuing with your submission, you may wish to:
      </h2>
      <ul>
        <li>Download the full list of questions in Word (.doc) format.</li>
        <li>
          View sample initiatives from the Global AI Public Policies &
          Initiatives Registry.
        </li>
      </ul>

      <h2 class="title is-5">During the survey</h2>
      <p>
        Once you begin the submission process, you'll be asked to enter or
        review: categories:
      </p>
      <ul>
        <li>
          Whether the initiative best fits one of the following categories:
          <ul>
            <li>National AI strategy</li>
            <li>AI governance bodies and mechanisms</li>
            <li>
              AI policy initiatives, regulations, guidelines, standards and
              programmes or projects
            </li>
          </ul>
        </li>

        <li>
          A description and details about the initiative, such as its name,
          status, budget (depending on the type of initiative), and the theme(s)
          it relates to, among others.
        </li>
        <li>Related AI initiatives.</li>
        <li>Links, relevant files, and other supporting material(s).</li>

        <p>
          Please remember to save your submission from time to time as you
          progress through the survey. You will find initiatives for %%
          [country/org] that have already been saved and/or published on the
          Global AI Public Policies & Initiatives Registry's administrative
          dashboard.
        </p>
      </ul>
    </div>
  `
})
export class IntroductionComponent {}
