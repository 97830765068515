import { ActionType } from '../../abacus/enums/action-type.enum'
import { InputType } from '../../abacus/enums/input-type.enum'
import { LinkType } from '../../abacus/enums/link-type.enum'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'
import { ResourceDefinition } from '../../abacus/interfaces/resource-definition.interface'
import { Status } from '../../common/enums/status.enum'

export const metricDefinition: ResourceDefinition = {
  title: 'Metrics',
  nameSingular: 'metric',
  namePlural: 'metrics',
  className: 'Metric',
  mainIdentifier: 'name',
  slug: 'metrics',
  path: 'metrics',
  icon: 'icon-bar-chart',
  hasDetailPage: true,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.DETAIL,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Edit',
      permission: 'editMetrics',
      action: (metric) => ({
        type: ActionType.Link,
        link: {
          path: `${metricDefinition.path}/${metric.id}/edit`
        }
      })
    },
    {
      label: 'Delete',
      permission: 'deleteMetrics',
      action: (metric) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: metric,
          definition: metricDefinition
        }
      })
    }
  ],
  actionButtons: [
    {
      label: 'Publish',
      className: 'button is-success p-x-lg is-small',
      permission: 'editMetrics',
      condition: (metric) => metric.status === Status.Pending,
      action: (metric) => ({
        type: ActionType.Patch,
        patch: {
          path: `metrics/${metric.id}/status`,
          body: { status: Status.Published },
          successMessage: 'The metric has been published',
          errorMessage: 'Error: metric could not be published',
          redirectTo: `metrics/${metric.id}`
        }
      })
    },
    {
      label: 'Reject',
      className: 'button is-danger ml-2 p-x-lg is-small',
      condition: (metric) => metric.status === Status.Pending,
      action: (metric) => ({
        type: ActionType.OpenCreateEditModal,
        openCreateEditModal: {
          title: `Reject the submission`,
          helpText: `You can provide a reason for rejecting the submission.`,
          definition: metricDefinition,
          mode: ResourceMode.Patch,
          item: metric,
          patchURL: `metrics/${metric.id}/status`,
          fields: [
            {
              label: 'Rejection comment',
              property: 'rejectReason',
              placeholder: 'Explain why you are rejecting this submission.',
              className: 'is-12',
              inputType: InputType.Textarea
            },
            {
              label: 'Status',
              property: 'status',
              forcedValue: Status.Rejected,
              inputType: InputType.Select,
              hidden: true
            }
          ],
          flashMessageOnSuccess: 'The metric has been rejected',
          flashMessageOnError: 'Error: metric could not be rejected',
          redirectTo: `metrics/${metric.id}`
        }
      })
    }
  ]
}
