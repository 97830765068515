import { ActionType } from '../../abacus/enums/action-type.enum'
import { LinkType } from '../../abacus/enums/link-type.enum'
import { ResourceDefinition } from '../../abacus/interfaces/resource-definition.interface'

export const geographicalScopeDefinition: ResourceDefinition = {
  title: 'Geographical Scopes',
  nameSingular: 'Geographical Scope',
  namePlural: 'Geographical Scopes',
  className: 'GeographicalScope',
  mainIdentifier: 'name',
  slug: 'taxonomies/geographical-scopes',
  path: 'geographical-scopes',
  icon: 'icon-grid',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Edit',
      permission: 'editTaxonomies',
      action: (geographicalScope) => ({
        type: ActionType.Link,
        link: {
          path: `${geographicalScopeDefinition.path}/${geographicalScope.id}/edit`
        }
      })
    },
    {
      label: 'Delete',
      permission: 'deleteTaxonomies',
      action: (geographicalScope) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: geographicalScope,
          definition: geographicalScopeDefinition
        }
      })
    }
  ]
}
