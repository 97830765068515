import { Route } from '@angular/router'

import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'

import { RequiredSkillCreateEditComponent } from './required-skill-create-edit/required-skill-create-edit.component'
import { RequiredSkillListComponent } from './required-skill-list/required-skill-list.component'

export const requiredSkillRoutes: Route[] = [
  {
    path: 'required-skills',
    component: RequiredSkillListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseTaxonomies'
    }
  },
  {
    path: 'required-skills/create',
    component: RequiredSkillCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addTaxonomies'
    }
  },
  {
    path: 'required-skills/:id/edit',
    component: RequiredSkillCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editTaxonomies'
    }
  }
]
