import { ActionType } from '../../abacus/enums/action-type.enum'
import { LinkType } from '../../abacus/enums/link-type.enum'
import { ResourceDefinition } from '../../abacus/interfaces/resource-definition.interface'

export const targetUserDefinition: ResourceDefinition = {
  title: 'TargetUsers',
  nameSingular: 'Target User',
  namePlural: 'Target Users',
  className: 'TargetUser',
  mainIdentifier: 'name',
  slug: 'taxonomies/target-users',
  path: 'target-users',
  icon: 'icon-grid',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Edit',
      permission: 'editTaxonomies',
      action: (targetUser) => ({
        type: ActionType.Link,
        link: {
          path: `${targetUserDefinition.path}/${targetUser.id}/edit`
        }
      })
    },
    {
      label: 'Delete',
      permission: 'deleteTaxonomies',
      action: (targetUser) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: targetUser,
          definition: targetUserDefinition
        }
      })
    }
  ]
}
