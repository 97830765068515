import { Yield } from '../../abacus/interfaces/yield.interface'

export const technologyPlatformYields: Yield[] = [
  {
    label: 'Name',
    property: 'name'
  },
  {
    label: 'Description',
    property: 'description'
  }
]
