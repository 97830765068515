import { InputType } from '../../../abacus/enums/input-type.enum'
import { Field } from '../../../abacus/interfaces/field.interface'

import { countryDefinition } from '../../country/country.definition'
import { impactedStakeholderDefinition } from '../../impacted-stakeholder/impacted-stakeholder.definition'
import { objectiveDefinition } from '../../objective/objective.definition'
import { organisationDefinition } from '../../organisation/organisation.definition'
import { purposeDefinition } from '../../purpose/purpose.definition'
import { riskDefinition } from '../../risk/risk.definition'
import { targetSectorDefinition } from '../../target-sector/target-sector.definition'
import { targetUserDefinition } from '../../target-user/target-user.definition'
import { toolDefinition } from '../../tool/tool.definition'

export const toolUseCaseCreateEditFields: Field[] = [
  {
    label: 'Tool use case name',
    placeholder: 'Enter the tool use case name...',
    property: 'name',
    className: 'is-6',
    inputType: InputType.Text,
    required: true
  },
  {
    label: 'Short Sentence',
    placeholder: 'Enter the short sentence...',
    property: 'shortSentence',
    required: true,
    className: 'is-12',
    inputType: InputType.Text
  },
  {
    label: 'Content',
    placeholder: 'Enter the content...',
    property: 'content',
    required: true,
    className: 'is-12',
    inputType: InputType.RichText
  },
  {
    label: 'Benefits',
    placeholder: 'Enter the benefits...',
    property: 'benefits',
    className: 'is-12',
    inputType: InputType.RichText
  },
  {
    label: 'Shortcomings',
    placeholder: 'Enter the shortcomings...',
    property: 'shortcomings',
    className: 'is-12',
    inputType: InputType.RichText
  },
  {
    label: 'Learnings',
    placeholder: 'Enter the learnings...',
    property: 'learnings',
    className: 'is-12',
    inputType: InputType.RichText
  },
  {
    label: 'Comparison',
    placeholder: 'Enter the comparisons...',
    property: 'comparison',
    className: 'is-12',
    inputType: InputType.RichText
  },
  {
    label: 'Email',
    placeholder: 'Enter the email...',
    property: 'email',
    className: 'is-6',
    inputType: InputType.Text,
    required: true
  },
  {
    label: 'Submitter',
    placeholder: 'Enter the submitter...',
    property: 'submitter',
    className: 'is-6',
    inputType: InputType.Text,
    required: true
  },
  {
    label: `Related tool`,
    property: 'toolId',
    retrievedItemProperties: {
      toolId: 'tool.id'
    },
    required: true,
    inputType: InputType.MultiSearch,
    showCreateButton: false,
    maxSelectedItems: 1,
    className: 'is-6',
    searchResources: [toolDefinition]
  },

  {
    label: 'Image Gallery',
    placeholder: 'Upload or pick the image of the tool use case...',
    property: 'image',
    inputType: InputType.ImageGallery,
    className: 'is-6'
  },

  {
    label: 'Organisations',
    properties: { organisationIds: 'organisationIds' },
    retrievedItemProperties: {
      organisationIds: 'organisationIds'
    },
    className: 'is-6',
    searchResources: [organisationDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Objectives',
    properties: {
      objectiveIds: 'objectiveIds'
    },
    retrievedItemProperties: {
      objectiveIds: 'objectiveIds'
    },
    className: 'is-6',
    searchResources: [objectiveDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Impacted stakeholders',
    properties: {
      impactedStakeholderIds: 'impactedStakeholderIds'
    },
    retrievedItemProperties: {
      impactedStakeholderIds: 'impactedStakeholderIds'
    },
    className: 'is-6',
    searchResources: [impactedStakeholderDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Purposes',
    properties: {
      purposeIds: 'purposeIds'
    },
    retrievedItemProperties: {
      purposeIds: 'purposeIds'
    },
    className: 'is-6',
    searchResources: [purposeDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Target sectors',
    properties: {
      targetSectorIds: 'targetSectorIds'
    },
    retrievedItemProperties: {
      targetSectorIds: 'targetSectorIds'
    },
    className: 'is-6',
    searchResources: [targetSectorDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Countries',
    properties: {
      countryIds: 'countryIds'
    },
    retrievedItemProperties: {
      countryIds: 'countryIds'
    },
    className: 'is-6',
    searchResources: [countryDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Target Users',
    properties: {
      targetUserIds: 'targetUserIds'
    },
    retrievedItemProperties: {
      targetUserIds: 'targetUserIds'
    },
    className: 'is-6',
    searchResources: [targetUserDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Risk Management Stages',
    properties: {
      riskIds: 'riskIds'
    },
    className: 'is-6',
    searchResources: [riskDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  }
]
