import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { AuthGuard } from './abacus/guards/auth.guard'
import { caseRoutes } from './abacus/routes/case.routes'
import { HomeComponent } from './pages/home/home.component'
import { aiPrincipleRoutes } from './resources/ai-principle/ai-principle.routes'
import { aiTagRoutes } from './resources/ai-tag/ai-tag.routes'
import { approachRoutes } from './resources/approach/approach.routes'
import { benefitRoutes } from './resources/benefit/benefit.routes'
import { coreApplicationAreaRoutes } from './resources/core-application-area/core-application-area.routes'
import { countryRoutes } from './resources/country/country.routes'
import { enforcementRoutes } from './resources/enforcement/enforcement.routes'
import { geographicalScopeRoutes } from './resources/geographical-scope/geographical-scope.routes'
import { impactedStakeholderRoutes } from './resources/impacted-stakeholder/impacted-stakeholder.routes'
import { initiativeTypeRoutes } from './resources/initiative-type/initiative-type.routes'
import { intergovernmentalOrganisationRoutes } from './resources/intergovernmental-organisation/intergovernmental-organisation.routes'
import { licenseRoutes } from './resources/license/license.routes'
import { lifecycleRoutes } from './resources/lifecycle/lifecycle.routes'
import { maturityRoutes } from './resources/maturity/maturity.routes'
import { metricTypeRoutes } from './resources/metric-type/metric-type.routes'
import { metricUseCaseRoutes } from './resources/metric-use-case/metric-use-case.routes'
import { metricRoutes } from './resources/metric/metric.routes'
import { nationalOrganisationRoutes } from './resources/national-organisation/national-organisation.routes'
import { objectiveRoutes } from './resources/objective/objective.routes'
import { organisationRoutes } from './resources/organisation/organisation.routes'
import { peopleInvolvedRoutes } from './resources/people-involved/people-involved.routes'
import { policyInitiativeRoutes } from './resources/policy-initiative/policy-initiative.routes'
import { programmingLanguageRoutes } from './resources/programming-language/programming-language.routes'
import { purposeRoutes } from './resources/purpose/purpose.routes'
import { requiredSkillRoutes } from './resources/required-skill/required-skill.routes'
import { riskRoutes } from './resources/risk/risk.routes'
import { stakeholderGroupRoutes } from './resources/stakeholder-group/stakeholder-group.routes'
import { tagRoutes } from './resources/tag/tag.routes'
import { targetGroupRoutes } from './resources/target-group/target-group.routes'
import { targetSectorRoutes } from './resources/target-sector/target-sector.routes'
import { targetUserRoutes } from './resources/target-user/target-user.routes'
import { technologyPlatformRoutes } from './resources/technology-platform/technology-platform.routes'
import { toolTypeRoutes } from './resources/tool-type/tool-type.routes'
import { toolUseCaseRoutes } from './resources/tool-use-case/tool-use-case.routes'
import { toolRoutes } from './resources/tool/tool.routes'
import { usageRightRoutes } from './resources/usage-right/usage-right.routes'
import { userRoutes } from './resources/user/user.routes'
import { validityRoutes } from './resources/validity/validity.routes'

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  ...userRoutes,
  ...toolRoutes,
  ...toolUseCaseRoutes,
  ...metricRoutes,
  ...metricUseCaseRoutes,
  ...policyInitiativeRoutes,
  ...programmingLanguageRoutes,
  ...tagRoutes,
  ...toolTypeRoutes,
  ...metricTypeRoutes,
  ...coreApplicationAreaRoutes,
  ...countryRoutes,
  ...lifecycleRoutes,
  ...impactedStakeholderRoutes,
  ...stakeholderGroupRoutes,
  ...purposeRoutes,
  ...licenseRoutes,
  ...targetUserRoutes,
  ...targetSectorRoutes,
  ...targetGroupRoutes,
  ...usageRightRoutes,
  ...maturityRoutes,
  ...organisationRoutes,
  ...objectiveRoutes,
  ...approachRoutes,
  ...geographicalScopeRoutes,
  ...enforcementRoutes,
  ...benefitRoutes,
  ...peopleInvolvedRoutes,
  ...requiredSkillRoutes,
  ...technologyPlatformRoutes,
  ...validityRoutes,
  ...nationalOrganisationRoutes,
  ...riskRoutes,
  ...intergovernmentalOrganisationRoutes,
  ...initiativeTypeRoutes,
  ...aiTagRoutes,
  ...aiPrincipleRoutes,
  ...(caseRoutes as Routes)
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
