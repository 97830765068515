import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core'
import { ValidatorFn, Validators } from '@angular/forms'

import { CaseInput } from '../../../interfaces/case-input.interface'
import { SelectOption } from '../../../interfaces/select-option.interface'

@Component({
  selector: 'case-radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.scss']
})
export class RadioInputComponent implements OnChanges, CaseInput {
  @Input() label: string
  @Input() placeholder: string
  @Input() helpText: string
  @Input() initialValue: string | number
  @Input() selectOptions: SelectOption[]
  @Input() showErrors = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string

  @Output() valueChanged: EventEmitter<string | number> = new EventEmitter()

  required: boolean

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    // Prevent value from being reset if showErrors changes.
    if (
      Object.keys(changes).length === 1 &&
      Object.keys(changes)[0] === 'showErrors'
    ) {
      return
    }

    if (this.initialValue) {
      setTimeout(() => {
        this.selectOptions.forEach((i) => {
          if (i.value === this.initialValue) {
            i.selected = true
          }
        })
      })
    }

    this.required = this.validators.includes(Validators.required)
  }

  /**
   *  Selects an option and emits the value. Unselects all other options.
   *
   * @param item the selected option
   *
   * @returns void
   */
  select(item: SelectOption) {
    if (item.disabled) {
      return
    }
    this.selectOptions.forEach((i) => {
      i.selected = false
    })

    item.selected = !item.selected
    this.valueChanged.emit(item.value)
  }

  /**
   * Returns true if no option is selected.
   *
   * @returns {boolean} Returns true if no option is selected.
   */
  isEmpty(): boolean {
    return this.selectOptions.every((i) => !i.selected)
  }
}
