import { Route } from '@angular/router'

import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'

import { ProgrammingLanguageCreateEditComponent } from './programming-language-create-edit/programming-language-create-edit.component'
import { ProgrammingLanguageListComponent } from './programming-language-list/programming-language-list.component'

export const programmingLanguageRoutes: Route[] = [
  {
    path: 'programming-languages',
    component: ProgrammingLanguageListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseTaxonomies'
    }
  },
  {
    path: 'programming-languages/create',
    component: ProgrammingLanguageCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addTaxonomies'
    }
  },
  {
    path: 'programming-languages/:id/edit',
    component: ProgrammingLanguageCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editTaxonomies'
    }
  }
]
