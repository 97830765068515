import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core'
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms'

import { CaseInput } from '../../../interfaces/case-input.interface'

@Component({
  selector: 'case-textarea-input',
  templateUrl: './textarea-input.component.html',
  styleUrls: ['./textarea-input.component.scss']
})
export class TextareaInputComponent implements OnChanges, CaseInput {
  @Input() label: string
  @Input() initialValue: string
  @Input() placeholder: string
  @Input() helpText: string
  @Input() htmlHelpText?: string
  @Input() htmlTooltip?: string
  @Input() htmlExample?: string
  @Input() showErrors = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string
  @Input() max?: number

  @Output() valueChanged: EventEmitter<string> = new EventEmitter()

  form: UntypedFormGroup
  required: boolean
  showExampleModal = false

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnChanges(changes: SimpleChanges) {
    // Prevent value from being reset if showErrors changes.
    if (
      Object.keys(changes).length === 1 &&
      Object.keys(changes)[0] === 'showErrors'
    ) {
      return
    }

    if (this.max) {
      this.validators.push(Validators.maxLength(this.max))
    }

    this.form = this.formBuilder.group({
      textarea: [this.initialValue || null, this.validators]
    })
    this.required = this.validators.includes(Validators.required)
  }

  onChange(newValue: string) {
    this.valueChanged.emit(newValue)
  }

  // Click outside closes modal.
  @HostListener('document:click', ['$event.target'])
  clickOut(eventTarget) {
    if (
      this.showExampleModal &&
      eventTarget.className.includes('modal-background')
    ) {
      this.showExampleModal = false
    }
  }
}
