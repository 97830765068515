import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import { ValidatorFn, Validators } from '@angular/forms'

import { CaseInput } from '../../../interfaces/case-input.interface'
import { SelectOption } from '../../../interfaces/select-option.interface'

@Component({
  selector: 'case-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss']
})
export class SelectInputComponent implements CaseInput, OnChanges {
  @Input() label: string
  @Input() placeholder: string
  @Input() helpText: string
  @Input() initialValue: string | number
  @Input() selectOptions: SelectOption[]
  @Input() readonly: boolean
  @Input() showErrors = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string
  @Input() required: boolean

  @Output() valueChanged: EventEmitter<string> = new EventEmitter()
  @ViewChild('select', { static: true }) select: ElementRef

  value: string = null

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    this.required =
      this.validators.includes(Validators.required) || this.required

    // If new select options are provided, reset form value if the previous value is not in the new options.
    if (
      changes.selectOptions &&
      !changes.selectOptions.firstChange &&
      !changes.selectOptions.currentValue
        .map((o) => o.value)
        .includes(this.value)
    ) {
      this.valueChanged.emit(null)
    } else {
      if (this.initialValue) {
        setTimeout(() => {
          this.select.nativeElement.value = this.initialValue
        }, 0)
      }
    }
  }

  onSelect(newValue: string) {
    this.value = newValue
    this.valueChanged.emit(this.value)
  }
}
