import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { CaseListComponent } from '../../abacus/components/case-list.component'
import { InputType } from '../../abacus/enums/input-type.enum'
import { Filter } from '../../abacus/interfaces/filter.interface'
import { ResourceDefinition } from '../../abacus/interfaces/resource-definition.interface'
import { Yield } from '../../abacus/interfaces/yield.interface'
import { AuthService } from '../../abacus/services/auth.service'
import { BreadcrumbService } from '../../abacus/services/breadcrumb.service'
import { FilterService } from '../../abacus/services/filter.service'
import { FlashMessageService } from '../../abacus/services/flash-message.service'
import { ResourceService } from '../../abacus/services/resource.service'
import { caseListTemplate } from '../../abacus/templates/case-list.template'
import { userDefinition } from './user.definition'
import { userYields } from './user.yields'

@Component({
  template: caseListTemplate
})
export class UserListComponent extends CaseListComponent implements OnInit {
  definition: ResourceDefinition = userDefinition
  yields: Yield[] = userYields
  filters: Filter[] = [
    {
      label: 'Search users',
      inputType: InputType.MultiSearch,
      searchResources: [userDefinition],
      properties: {
        userIds: 'userIds'
      }
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    authService: AuthService,
    filterService: FilterService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService,
      authService,
      filterService
    )
  }

  ngOnInit(): void {
    this.initListView()
  }
}
