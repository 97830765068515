import { InputType } from '../../../../abacus/enums/input-type.enum'
import { Field } from '../../../../abacus/interfaces/field.interface'

export const step4Fields: Field[] = [
  {
    label: 'Name for step 4',
    placeholder: 'Enter the name...',
    property: 'nameStep4',
    className: 'is-6',
    inputType: InputType.Text,
    required: true
  }
]
