import { ActionType } from '../../abacus/enums/action-type.enum'
import { InputType } from '../../abacus/enums/input-type.enum'
import { LinkType } from '../../abacus/enums/link-type.enum'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'
import { ResourceDefinition } from '../../abacus/interfaces/resource-definition.interface'
import { Status } from '../../common/enums/status.enum'

export const toolUseCaseDefinition: ResourceDefinition = {
  title: 'Tool use cases',
  nameSingular: 'tool use case',
  namePlural: 'tool use cases',
  className: 'ToolUseCase',
  mainIdentifier: 'name',
  slug: 'tool-use-cases',
  path: 'tool-use-cases',
  icon: 'icon-layers',
  hasDetailPage: true,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.DETAIL,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Edit',
      permission: 'editToolUseCases',
      action: (toolUseCase) => ({
        type: ActionType.Link,
        link: {
          path: `${toolUseCaseDefinition.path}/${toolUseCase.id}/edit`
        }
      })
    },
    {
      label: 'Delete',
      permission: 'deleteToolUseCases',
      action: (toolUseCase) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: toolUseCase,
          definition: toolUseCaseDefinition
        }
      })
    }
  ],
  actionButtons: [
    {
      label: 'Publish',
      className: 'button is-success p-x-lg is-small',
      permission: 'editToolUseCases',
      condition: (toolUseCase) => toolUseCase.status === Status.Pending,
      action: (toolUseCase) => ({
        type: ActionType.Patch,
        patch: {
          path: `tool-use-cases/${toolUseCase.id}/status`,
          body: { status: Status.Published },
          successMessage: 'The tool use case has been published',
          errorMessage: 'Error: tool use case could not be published',
          redirectTo: `tool-use-cases/${toolUseCase.id}`
        }
      })
    },
    {
      label: 'Reject',
      className: 'button is-danger ml-2 p-x-lg is-small',
      condition: (toolUseCase) => toolUseCase.status === Status.Pending,
      action: (toolUseCase) => ({
        type: ActionType.OpenCreateEditModal,
        openCreateEditModal: {
          title: `Reject the submission`,
          helpText: `Please provide a reason for rejecting the submission.`,
          definition: toolUseCaseDefinition,
          mode: ResourceMode.Patch,
          item: toolUseCase,
          patchURL: `tool-use-cases/${toolUseCase.id}/status`,
          fields: [
            {
              label: 'Rejection comment',
              property: 'rejectReason',
              placeholder: 'Explain why you are rejecting this submission.',
              className: 'is-12',
              inputType: InputType.Textarea
            },
            {
              label: 'Status',
              property: 'status',
              forcedValue: Status.Rejected,
              inputType: InputType.Select,
              hidden: true
            }
          ],
          flashMessageOnSuccess: 'The tool use case has been rejected',
          flashMessageOnError: 'Error: tool use case could not be rejected',
          redirectTo: `tool-use-cases/${toolUseCase.id}`
        }
      })
    }
  ]
}
