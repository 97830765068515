import { ActionType } from '../../abacus/enums/action-type.enum'
import { LinkType } from '../../abacus/enums/link-type.enum'
import { ResourceDefinition } from '../../abacus/interfaces/resource-definition.interface'

export const toolTypeDefinition: ResourceDefinition = {
  title: 'Tool Types',
  nameSingular: 'Tool Type',
  namePlural: 'Tool Types',
  className: 'ToolType',
  mainIdentifier: 'name',
  slug: 'taxonomies/tool-types',
  path: 'tool-types',
  icon: 'icon-grid',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Edit',
      permission: 'editTaxonomies',
      action: (toolType) => ({
        type: ActionType.Link,
        link: {
          path: `${toolTypeDefinition.path}/${toolType.id}/edit`
        }
      })
    },
    {
      label: 'Delete',
      permission: 'deleteToolTypes',
      action: (toolType) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: toolType,
          definition: toolTypeDefinition
        }
      })
    }
  ]
}
