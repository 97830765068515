import { Route } from '@angular/router'

import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'

import { OrganisationCreateEditComponent } from './organisation-create-edit/organisation-create-edit.component'
import { OrganisationListComponent } from './organisation-list/organisation-list.component'

export const organisationRoutes: Route[] = [
  {
    path: 'organisations',
    component: OrganisationListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseTaxonomies'
    }
  },
  {
    path: 'organisations/create',
    component: OrganisationCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addTaxonomies'
    }
  },
  {
    path: 'organisations/:id/edit',
    component: OrganisationCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editTaxonomies'
    }
  }
]
