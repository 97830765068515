import { Route } from '@angular/router'

import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'

import { ToolUseCaseCreateEditComponent } from './tool-use-case-create-edit/tool-use-case-create-edit.component'
import { ToolUseCaseDetailComponent } from './tool-use-case-detail/tool-use-case-detail.component'
import { ToolUseCaseEditForSubmitterComponent } from './tool-use-case-edit-for-submitter/tool-use-case-edit-for-submitter.component'
import { ToolUseCaseListComponent } from './tool-use-case-list/tool-use-case-list.component'

export const toolUseCaseRoutes: Route[] = [
  {
    path: 'tool-use-cases',
    component: ToolUseCaseListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseToolUseCases'
    }
  },
  {
    path: 'tool-use-cases/create',
    component: ToolUseCaseCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addToolUseCases'
    }
  },
  {
    path: 'tool-use-cases/:id',
    component: ToolUseCaseDetailComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'readToolUseCases'
    }
  },
  {
    path: 'tool-use-cases/:id/edit',
    component: ToolUseCaseCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editToolUseCases'
    }
  },
  {
    path: 'submitter/tool-use-cases/:id/edit',
    component: ToolUseCaseEditForSubmitterComponent,
    data: {
      mode: ResourceMode.Edit
    }
  }
]
