import { ActionType } from '../../abacus/enums/action-type.enum'
import { LinkType } from '../../abacus/enums/link-type.enum'
import { ResourceDefinition } from '../../abacus/interfaces/resource-definition.interface'

export const coreApplicationAreaDefinition: ResourceDefinition = {
  title: 'Core applications',
  nameSingular: 'Core application',
  namePlural: 'Core applications',
  className: 'CoreApplicationArea',
  mainIdentifier: 'name',
  slug: 'taxonomies/core-application-areas',
  path: 'core-application-areas',
  icon: 'icon-grid',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Edit',
      permission: 'editTaxonomies',
      action: (coreApplicationArea) => ({
        type: ActionType.Link,
        link: {
          path: `${coreApplicationAreaDefinition.path}/${coreApplicationArea.id}/edit`
        }
      })
    },
    {
      label: 'Delete',
      permission: 'deleteToolTypes',
      action: (coreApplicationArea) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: coreApplicationArea,
          definition: coreApplicationAreaDefinition
        }
      })
    }
  ]
}
