import { Component, OnInit } from '@angular/core'

import { BreadcrumbService } from '../../abacus/services/breadcrumb.service'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(private breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    this.breadcrumbService.breadcrumbLinks.next([
      {
        label: `Home`
      }
    ])
  }
}
