import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { CaseListComponent } from '../../../abacus/components/case-list.component'
import { InputType } from '../../../abacus/enums/input-type.enum'
import { Filter } from '../../../abacus/interfaces/filter.interface'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { Yield } from '../../../abacus/interfaces/yield.interface'
import { AuthService } from '../../../abacus/services/auth.service'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FilterService } from '../../../abacus/services/filter.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { ResourceService } from '../../../abacus/services/resource.service'
import { caseListTemplate } from '../../../abacus/templates/case-list.template'
import { tagDefinition } from '../../tag/tag.definition'
import { toolDefinition } from '../tool.definition'
import { toolYields } from '../tool.yields'
import { Status } from '../../../common/enums/status.enum'

@Component({ template: caseListTemplate })
export class ToolListComponent extends CaseListComponent implements OnInit {
  definition: ResourceDefinition = toolDefinition
  yields: Yield[] = toolYields

  filters: Filter[] = [
    {
      label: 'Search a tool',
      placeholder: 'Search a tool by name or by tag...',
      properties: {
        toolIds: 'toolIds',
        tagIds: 'tagIds '
      },
      inputType: InputType.MultiSearch,
      searchResources: [toolDefinition, tagDefinition],
      className: 'is-6'
    },
    {
      label: 'Status',
      property: 'status',
      placeholder: 'All status',
      inputType: InputType.Select,
      selectOptions: [
        {
          label: 'Published',
          value: Status.Published
        },
        {
          label: 'Pending',
          value: Status.Pending
        },
        {
          label: 'Rejected',
          value: Status.Rejected
        }
      ]
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    authService: AuthService,
    filterService: FilterService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService,
      authService,
      filterService
    )
  }

  ngOnInit() {
    this.initListView()
  }
}
