import { ActionType } from '../../abacus/enums/action-type.enum'
import { LinkType } from '../../abacus/enums/link-type.enum'
import { ResourceDefinition } from '../../abacus/interfaces/resource-definition.interface'

export const initiativeTypeDefinition: ResourceDefinition = {
  title: 'Initiative Types',
  nameSingular: 'Initiative Type',
  namePlural: 'Initiative Types',
  className: 'InitiativeType',
  mainIdentifier: 'name',
  slug: 'taxonomies/initiative-types',
  path: 'initiative-types',
  icon: 'icon-tag',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Edit',
      permission: 'editPolicyInitiativeTaxonomies',
      action: (initiativeType) => ({
        type: ActionType.Link,
        link: {
          path: `${initiativeTypeDefinition.path}/${initiativeType.id}/edit`
        }
      })
    },
    {
      label: 'Delete',
      permission: 'deletePolicyInitiativeTaxonomies',
      action: (initiativeType) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: initiativeType,
          definition: initiativeTypeDefinition
        }
      })
    }
  ]
}
