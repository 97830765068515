import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { CaseDetailComponent } from '../../../abacus/components/case-detail.component'
import { ActionType } from '../../../abacus/enums/action-type.enum'
import { InputType } from '../../../abacus/enums/input-type.enum'
import { ResourceMode } from '../../../abacus/enums/resource-mode.enum'
import { Action } from '../../../abacus/interfaces/actions/action.interface'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { ResourceService } from '../../../abacus/services/resource.service'
import { toolDefinition } from '../tool.definition'
import { Duration } from '../../../common/enums/duration.enum'
import { Status } from '../../../common/enums/status.enum'
import { Entity } from '../../../common/enums/entity.enum'

@Component({
  selector: 'app-tool-detail',
  templateUrl: './tool-detail.component.html',
  styleUrls: ['./tool-detail.component.scss']
})
export class ToolDetailComponent extends CaseDetailComponent implements OnInit {
  definition: ResourceDefinition = toolDefinition

  Duration = Duration

  Status = Status

  Entity = Entity

  publishAction: Action
  deleteAction: Action
  rejectAction: Action
  pendingAction: Action

  toolRelations: { label: string; propName: string }[] = [
    { label: 'Tags', propName: 'tags' },
    { label: 'Programming Languages', propName: 'programmingLanguages' },
    { label: 'Lifecycles', propName: 'lifecycles' },
    { label: 'Approaches', propName: 'approaches' },
    { label: 'Purposes', propName: 'purposes' },
    { label: 'Organisations', propName: 'organisations' },
    { label: 'Objectives', propName: 'objectives' },
    { label: 'Maturities', propName: 'maturities' },
    { label: 'Tool Types', propName: 'toolTypes' },
    { label: 'Usage Rights', propName: 'usageRights' },
    { label: 'Impacted Stakeholders', propName: 'impactedStakeholders' },
    { label: 'Stakeholder Groups', propName: 'stakeholderGroups' },
    { label: 'Target Sectors', propName: 'targetSectors' },
    { label: 'Target Groups', propName: 'targetGroups' },
    { label: 'Target Users', propName: 'targetUsers' },
    { label: 'Geographical Scopes', propName: 'geographicalScopes' },
    { label: 'Technology Platforms', propName: 'technologyPlatforms' },
    { label: 'Required Skills', propName: 'requiredSkills' },
    { label: 'People Involved', propName: 'peopleInvolveds' },
    { label: 'Benefits', propName: 'benefits' },
    { label: 'Validities', propName: 'validities' },
    { label: 'Enforcements', propName: 'enforcements' },
    { label: 'Countries', propName: 'countries' },
    { label: 'Risk Management Stages', propName: 'risks' }
  ]

  itemRelations = [
    ...this.toolRelations.map((relation) => relation.propName),
    'licenses',
    'toolUseCases'
  ]

  constructor(
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute
  ) {
    super(
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  async ngOnInit(): Promise<void> {
    await this.initDetailView()

    this.deleteAction = {
      type: ActionType.Delete,
      delete: {
        itemToDelete: this.item,
        definition: toolDefinition,
        redirectTo: '/tools'
      }
    }
    this.publishAction = {
      type: ActionType.Patch,
      patch: {
        path: `tools/${this.item.id}/status`,
        body: { status: Status.Published },
        successMessage: 'The tool has been published.',
        errorMessage: 'Error: Tool could not be published'
      }
    }
    this.pendingAction = {
      type: ActionType.Patch,
      patch: {
        path: `tools/${this.item.id}/status`,
        body: { status: Status.Pending },
        successMessage: 'The tool has been switch to pending.',
        errorMessage: 'Error: Tool could not be rejected'
      }
    }
    this.rejectAction = {
      type: ActionType.OpenCreateEditModal,
      openCreateEditModal: {
        title: `Reject the submission`,
        helpText: `You can provide a reason for rejecting the submission.`,
        definition: toolDefinition,
        mode: ResourceMode.Patch,
        item: this.item,
        patchURL: `tools/${this.item.id}/status`,
        fields: [
          {
            label: 'Rejection comment',
            property: 'rejectReason',
            placeholder: 'Explain why you are rejecting this submission.',
            className: 'is-12',
            inputType: InputType.Textarea
          },
          {
            label: 'Status',
            property: 'status',
            forcedValue: Status.Rejected,
            inputType: InputType.Select,
            hidden: true
          }
        ]
      }
    }
  }
}
