import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-step-progress-bar',
  templateUrl: './step-progress-bar.component.html',
  styleUrls: ['./step-progress-bar.component.scss']
})
export class StepProgressBarComponent implements OnInit {
  stepsArray: number[] = []

  @Input() stepCount: number = 0
  @Input() currentStep: number = 0

  @Output() stepClicked: EventEmitter<number> = new EventEmitter()

  ngOnInit(): void {
    this.stepsArray = Array.from({ length: this.stepCount }, (v, k) => k + 1)
  }

  /**
   * Called when a step is clicked. Only emit the event if the step is less than the current step as forms can be conditional.
   *
   * @param step The step that was clicked.
   */
  onStepClicked(step: number): void {
    if (step < this.currentStep) {
      this.stepClicked.emit(step)
    }
  }
}
