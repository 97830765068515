import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { UntypedFormBuilder } from '@angular/forms'

import { caseCreateEditTemplate } from '../../../abacus/templates/case-create-edit.template'
import { CaseCreateEditComponent } from '../../../abacus/components/case-create-edit.component'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { Field } from '../../../abacus/interfaces/field.interface'
import { InputType } from '../../../abacus/enums/input-type.enum'
import { ResourceService } from '../../../abacus/services/resource.service'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'

import { tagDefinition } from '../tag.definition'

@Component({ template: caseCreateEditTemplate })
export class TagCreateEditComponent
  extends CaseCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = tagDefinition
  fields: Field[] = [
    {
      label: 'Name',
      placeholder: 'Enter the name...',
      property: 'name',
      className: 'is-6',
      inputType: InputType.Text,
      required: true
    },
    {
      label: 'Description',
      placeholder: 'Enter the description...',
      property: 'description',
      className: 'is-6',
      inputType: InputType.Text
    },
    {
      label: 'Order',
      placeholder: 'Order of display (higher value appears first)...',
      property: 'order',
      className: 'is-6',
      inputType: InputType.Number
    }
  ]

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }
}
