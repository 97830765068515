import { Component, OnInit } from '@angular/core'
import { FormGroup, UntypedFormBuilder } from '@angular/forms'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { CaseCreateEditComponent } from '../../../abacus/components/case-create-edit.component'
import { Field } from '../../../abacus/interfaces/field.interface'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { ResourceService } from '../../../abacus/services/resource.service'
import { policyInitiativeDefinition } from '../policy-initiative.definition'
import { getStep1Fields } from './fields/step-1-fields'
import { step2Fields } from './fields/step-2-fields'
import { step3Fields } from './fields/step-3-fields'
import { step4Fields } from './fields/step-4-fields'

@Component({
  templateUrl: './policy-initiative-create-edit.component.html',
  styleUrls: ['./policy-initiative-create-edit.component.scss']
})
export class PolicyInitiativeCreateEditComponent
  extends CaseCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = policyInitiativeDefinition

  loading = false

  stepFields: Field[][] = [
    [], // Step 0 has no fields.
    getStep1Fields(this),
    step2Fields,
    step3Fields,
    step4Fields
  ]

  currentStep: number = 0

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    private componentActivatedRoute: ActivatedRoute,
    private componentRouter: Router,
    private componentFlashMessageService: FlashMessageService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.componentActivatedRoute.queryParams.subscribe(
      async (params: Params) => {
        this.showErrors = false
        this.currentStep = params.step ? parseInt(params.step, 10) : 0
        this.fields = this.stepFields[this.currentStep]

        await this.initCreateEditView()
      }
    )
  }

  /**
   * Save the form as draft and go to a defined step. Validate the fields of the current step before proceeding.
   *
   * @param step the step to go to.
   * @param skipPristine if true, the pristine fields will be skipped (useful for going back to a previous step).
   *
   * @returns a promise resolved when the draft is saved.
   */
  async saveAndGoToStep({
    step,
    skipPristineValidation
  }: {
    step: number
    skipPristineValidation?: boolean
  }): Promise<void> {
    const stepInvalidFields: Field[] = this.getInvalidFields({
      fields: this.fields,
      form: this.form,
      skipPristineValidation
    })

    if (stepInvalidFields.length) {
      this.showErrors = true
      return this.componentFlashMessageService.error(
        'Some fields are invalid. Please correct them before proceeding: ' +
          stepInvalidFields.map((field: Field) => field.label).join(', ')
      )
    }

    await this.saveDraft()

    this.componentRouter.navigate([], {
      queryParams: { step },
      queryParamsHandling: 'merge'
    })
  }

  /**
   * Check if the fields are valid for a given set of fields.
   *
   * @param fields the fields to check.
   * @param form the form to check the fields against.
   * @param skipPristineValidation if true, the pristine fields will be skipped (useful for going back to a previous step).
   *
   * @returns the invalid fields.
   */
  getInvalidFields({
    fields,
    form,
    skipPristineValidation
  }: {
    fields: Field[]
    form: FormGroup
    skipPristineValidation?: boolean
  }): Field[] {
    if (skipPristineValidation) {
      return fields.filter(
        (field: Field) =>
          form.get(field.property).invalid && !form.get(field.property).pristine
      )
    }

    return fields.filter((field: Field) => {
      const fieldProperties: string[] = field.property
        ? [field.property]
        : Object.keys(field.properties)

      return fieldProperties.some(
        (property: string) => form.get(property).invalid
      )
    })
  }

  // TODO: implement the save method.
  async save(): Promise<void> {
    alert('Policy Initiative saved!')
    return Promise.resolve()
  }

  // TODO: implement the saveDraft method.
  async saveDraft(): Promise<void> {
    this.loading = true

    await new Promise((resolve) => {
      setTimeout(() => {
        this.loading = false
        resolve(null) // Resolve the promise after 1 second
      }, 1000)
    })
  }
}
