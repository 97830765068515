import { Route } from '@angular/router'

import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'

import { TargetGroupCreateEditComponent } from './target-group-create-edit/target-group-create-edit.component'
import { TargetGroupListComponent } from './target-group-list/target-group-list.component'

export const targetGroupRoutes: Route[] = [
  {
    path: 'target-groups',
    component: TargetGroupListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseTaxonomies'
    }
  },
  {
    path: 'target-groups/create',
    component: TargetGroupCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addTaxonomies'
    }
  },
  {
    path: 'target-groups/:id/edit',
    component: TargetGroupCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editTaxonomies'
    }
  }
]
