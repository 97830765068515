import { Validators } from '@angular/forms'
import moment from 'moment'
import { InitiativeCategory } from '../../../../common/enums/policy-initiative/initiative-category.enum'
import { InitiativeStatus } from '../../../../common/enums/policy-initiative/initiative-status.enum'
import { InputType } from '../../../../abacus/enums/input-type.enum'
import { Field } from '../../../../abacus/interfaces/field.interface'
import { aiTagDefinition } from '../../../ai-tag/ai-tag.definition'
import { targetGroupDefinition } from '../../../target-group/target-group.definition'
import { PolicyInitiativeCreateEditComponent } from '../policy-initiative-create-edit.component'

// Those fields appear for all categories and sub-categories.
const generalFieldIds: string[] = [
  'englishName',
  'originalName',
  'status',
  'startYear',
  'endYear',
  'website',
  'description',
  'overview',
  'targetSectors',
  'tags'
]

// Build the select options for the start year and end year fields.
const startYearFrom: number = 1956
const startYearTo: number = moment().add(5, 'year').year()
const endYearFrom: number = 2005
const endYearTo: number = moment().add(30, 'year').year()

// Conditional help text for the description field.
const descriptionHtmlHelpTextIfInterGovernmental: string = `
<div class="content is-small">  
    <p>
      This explanation should describe the AI initiative in about three short and simple sentences. This is one of the most important fields. The explanation should:
    </p>
    <ul>
        <li>Use clear and succinct language</li>
        <li>Be able to be understood by someone who is not familiar with the sector or initiative</li>
        <li>Set the context for policymakers and other users who are reading about the initiative</li>
        <li>Use simple, not sector-specific terminology (avoiding idioms or "buzz" words)</li>
    </ul>
    <p>The explanation should describe:</p>
    <ul>
        <li>What are the objectives of the strategy?</li>
        <li>What are the pillars of the strategy and what sectors does it focus on (e.g., public sector, health, agriculture)?</li>
    </ul>
    </div>
`
const descriptionHtmlHelpTextIfNational: string = `
   <p>
        This explanation should describe the AI initiative in about three short and simple sentences. This is one of the most important fields. The explanation should:
    </p>
    <ul>
        <li>Use clear and succinct language</li>
        <li>Be able to be understood by someone who is not familiar with the sector or initiative</li>
        <li>Set the context for policymakers and other users who are reading about the initiative</li>
        <li>Use simple, not sector-specific terminology (avoiding idioms or "buzz" words)</li>
    </ul>
    <p>The explanation should describe:</p>
    <ul>
        <li>What the initiative is and who may benefit</li>
        <li>Why the initiative was developed or the problem/opportunity being addressed</li>
    </ul>
`

const htmlTooltipIfInterGovernmental: string = `
  <h4>Legally Binding and Non-Legally Binding Instruments</h4>
  <br />  
  <p>Legally binding: Directly binding on contracting parties either upon signature or upon ratification depending on the provisions of the instrument (e.g. treaties and conventions, agreements, decisions, and other forms of prescriptive instruments).</p>
  <p>Non-legally binding: By nature or wording, these are not intended to be legally binding.</p>
  <ul>
  <li>• Where States transpose these instruments (or some of their provisions) into domestic legislation or recognise them in international legally binding instruments such as treaties, the relevant instruments or provisions acquire legally binding value (e.g. Mutual Recognition Agreements, model laws, legislative guides).</li>
  <li>• Statements of intent or guidance which are aimed specifically at encouraging certain behaviours and pooling experiences, or framing priorities and expressing commitments (e.g. declarations, guidelines, best practices).</li>
  </ul>
  `

export const getStep1Fields = (
  context: PolicyInitiativeCreateEditComponent
): Field[] => [
  {
    id: 'category',
    label: 'Category',
    helpText:
      'Please indicate the level of government responsible for the initiative',
    placeholder: 'Enter the name...',
    property: 'category',
    className: 'is-12',
    inputType: InputType.Radio,
    selectOptions: [
      {
        label: 'Intergovernmental or supranational',
        subLabel:
          'This includes AI initiatives from intergovernmental organisations (e.g. OECD, United Nations) and supranational organisations (e.g. European Union, Arab League)',
        value: InitiativeCategory.Intergovernmental
      },
      {
        label: 'National',
        subLabel:
          'This includes AI initiatives from national countries or territories, as well as cross-border initiatives conducted jointly by multiple countries or territories',
        value: InitiativeCategory.NationalGeneral
      }
    ],
    onChange: async (newValue: InitiativeCategory) => {
      const subCategoryField: Field = context.getFieldById('subCategory')
      const descriptionField: Field = context.getFieldById('description')
      const overviewField: Field = context.getFieldById('overview')

      if (newValue === InitiativeCategory.Intergovernmental) {
        context.showFields(generalFieldIds)
        await context.hideAndResetFields(['subCategory'])

        descriptionField.htmlHelpText =
          descriptionHtmlHelpTextIfInterGovernmental
        descriptionField.htmlTooltip = htmlTooltipIfInterGovernmental
        overviewField.htmlTooltip = htmlTooltipIfInterGovernmental
      } else {
        context.showFields(['subCategory'])
        subCategoryField.validators = [Validators.required]
        await context.resetFieldFormControls(subCategoryField)

        descriptionField.htmlHelpText = descriptionHtmlHelpTextIfNational
        descriptionField.htmlTooltip = ''
        overviewField.htmlTooltip = ''
      }
    },
    required: true
  },
  {
    id: 'subCategory',
    label: 'Sub-category',
    placeholder: 'Enter the name...',
    property: 'subCategory',
    className: 'is-12',
    hidden: true, // TODO: On edit mode this should be displayed if relevant.
    inputType: InputType.Radio,
    required: false,
    selectOptions: [
      {
        label: 'National AI Strategy',
        value: InitiativeCategory.NationalGeneral
      },
      {
        label: 'AU Governance Bodies or Mechanisms',
        subLabel:
          'Governance and coordination bodies, offices and processes; advisory bodies; monitoring mechanisms; public or expert consultations',
        value: InitiativeCategory.NationalGovernance
      },
      {
        label:
          'AI Policy Initiatives, regulations, guideline, standards and programmes or projects',
        subLabel:
          'Laws, regulations, public policies or strategies, strategies on specific topics or in adjacent fields (e.g. compute capacity or data strategies)',
        value: InitiativeCategory.NationalStrategy
      }
    ]
  },
  {
    id: 'englishName',
    label: 'Name in english',
    helpText:
      'Please enter the name of the initiative in English, including the acronym if relevant',
    placeholder: 'Enter the name in English...',
    property: 'englishName',
    className: 'is-12',
    inputType: InputType.Text,
    required: true,
    hidden: true
  },
  {
    id: 'originalName',
    label: 'Name in original language',
    helpText:
      'If the name of the initiative if not originally in English, please enter the name in its original language',
    placeholder: 'Enter the name in English...',
    property: 'originalName',
    className: 'is-12',
    inputType: InputType.Text,
    hidden: true
  },
  {
    id: 'status',
    label: 'Status of initiative',
    property: 'status',
    className: 'is-12',
    inputType: InputType.Select,
    selectOptions: Object.entries(InitiativeStatus).map(([key, value]) => ({
      value: InitiativeStatus[key],
      label: value
    })),
    required: true,
    hidden: true
  },
  {
    id: 'startYear',
    label: 'Start year',
    property: 'startYear',
    helpText:
      'Please enter the anticipated start date of Proposed initiatives, or the actual start date of Active and Complete initiatives.',
    className: 'is-12',
    inputType: InputType.Select,
    selectOptions: Array.from(
      { length: startYearTo - startYearFrom + 1 },
      (_, index) => startYearFrom + index
    ).map((year: number) => ({
      value: year,
      label: year.toString()
    })),
    required: true,
    hidden: true
  },
  {
    id: 'endYear',
    label: 'End year',
    property: 'endYear',
    helpText:
      'Please enter the anticipated end date of the initiative (if there is one), or if the initiative has already concluded, the actual end date.',
    className: 'is-12',
    inputType: InputType.Select,
    selectOptions: [{ label: 'Not applicable', value: 0 }].concat(
      Array.from(
        { length: endYearTo - endYearFrom + 1 },
        (_, index) => endYearFrom + index
      ).map((year: number) => ({
        value: year,
        label: year.toString()
      }))
    ),
    required: true,
    hidden: true
  },
  {
    id: 'website',
    label: 'Website',
    property: 'website',
    className: 'is-12',
    inputType: InputType.Text,
    hidden: true
  },
  {
    id: 'description',
    label: 'Short description',
    property: 'description',
    className: 'is-12',
    inputType: InputType.Textarea,
    htmlExample: `
    <p>This is an example of an HTML content that can be used to provide a short description of the initiative.</p>
    <p>It can include multiple paragraphs, lists, links, and other HTML elements.</p>
    `,
    max: 500,
    required: true,
    hidden: true
  },
  {
    id: 'overview',
    label: 'Overview',
    property: 'overview',
    htmlHelpText: `
    <div class="content is-small">
      <p>
        The initiative overview is an optional expanded overview of the initiative and its intended or demonstrated outcomes. This could potentially be the same text as an abstract, executive summary, fact sheet, or one-pager about the initiative.
    </p>
    <p>
        Going beyond the short and simple description, this should provide an expanded set of information for the initiative at a high level. In approximately 3-4 paragraphs (maximum 3,000 characters), please share additional information about the initiative, such as:
    </p>
    <ul>
        <li><strong>What</strong> issue does the initiative seek to resolve or what opportunity does it seek to leverage?</li>
        <li><strong>What</strong> are the objectives of the initiative?</li>
        <li><strong>How</strong> is the initiative expected to evolve in the future? For example, how will it be implemented and institutionalised? How could it scale more broadly?</li>
        <li><strong>How</strong> has the initiative <strong>evolved</strong> over time? (especially relevant for periodic updates)</li>
    </ul>
    </div>
    `,
    htmlExample: `
    <p>This is an example of an HTML content that can be used to provide an overview of the initiative.</p>
    <p>It can include multiple paragraphs, lists, links, and other HTML elements.</p>
    `,
    className: 'is-12',
    inputType: InputType.RichText,
    validators: [Validators.maxLength(3000)],
    max: 3000,
    hidden: true
  },
  {
    id: 'targetSectors',
    label: 'Relevant sectors covered by this initiative',
    className: 'is-12',
    inputType: InputType.MultiSearch,
    searchResources: [targetGroupDefinition],
    properties: {
      targetSectorIds: 'targetSectorIds'
    },
    property: 'targetSectors',
    required: true,
    min: 1,
    hidden: true
  },
  {
    id: 'tags',
    label: 'AI tags',
    className: 'is-12',
    helpText: 'Please add any tags that are highly relevant to this initiative',
    inputType: InputType.MultiSearch,
    searchResources: [aiTagDefinition],
    properties: {
      aiTagIds: 'aiTagIds'
    },
    hidden: true
  }
]
