import { Route } from '@angular/router'

import { ResourceMode } from '../../abacus/enums/resource-mode.enum'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { PermissionGuard } from '../../abacus/guards/permission.guard'

import { AITagCreateEditComponent } from './ai-tag-create-edit/ai-tag-create-edit.component'
import { AITagListComponent } from './ai-tag-list/ai-tag-list.component'

export const aiTagRoutes: Route[] = [
  {
    path: 'ai-tags',
    component: AITagListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browsePolicyInitiativeTaxonomies'
    }
  },
  {
    path: 'ai-tags/create',
    component: AITagCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addPolicyInitiativeTaxonomies'
    }
  },
  {
    path: 'ai-tags/:id/edit',
    component: AITagCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editPolicyInitiativeTaxonomies'
    }
  }
]
