import { InputType } from '../../../abacus/enums/input-type.enum'
import { Field } from '../../../abacus/interfaces/field.interface'

import { approachDefinition } from '../../approach/approach.definition'
import { benefitDefinition } from '../../benefit/benefit.definition'
import { countryDefinition } from '../../country/country.definition'
import { enforcementDefinition } from '../../enforcement/enforcement.definition'
import { geographicalScopeDefinition } from '../../geographical-scope/geographical-scope.definition'
import { impactedStakeholderDefinition } from '../../impacted-stakeholder/impacted-stakeholder.definition'
import { licenseDefinition } from '../../license/license.definition'
import { lifecycleDefinition } from '../../lifecycle/lifecycle.definition'
import { maturityDefinition } from '../../maturity/maturity.definition'
import { objectiveDefinition } from '../../objective/objective.definition'
import { organisationDefinition } from '../../organisation/organisation.definition'
import { peopleInvolvedDefinition } from '../../people-involved/people-involved.definition'
import { programmingLanguageDefinition } from '../../programming-language/programming-language.definition'
import { purposeDefinition } from '../../purpose/purpose.definition'
import { requiredSkillDefinition } from '../../required-skill/required-skill.definition'
import { riskDefinition } from '../../risk/risk.definition'
import { stakeholderGroupDefinition } from '../../stakeholder-group/stakeholder-group.definition'
import { tagDefinition } from '../../tag/tag.definition'
import { targetGroupDefinition } from '../../target-group/target-group.definition'
import { targetSectorDefinition } from '../../target-sector/target-sector.definition'
import { targetUserDefinition } from '../../target-user/target-user.definition'
import { technologyPlatformDefinition } from '../../technology-platform/technology-platform.definition'
import { toolTypeDefinition } from '../../tool-type/tool-type.definition'
import { usageRightDefinition } from '../../usage-right/usage-right.definition'
import { validityDefinition } from '../../validity/validity.definition'

export const toolCreateEditFields: Field[] = [
  {
    label: 'Tool name',
    placeholder: 'Enter the tool name...',
    property: 'name',
    className: 'is-6',
    inputType: InputType.Text,
    required: true
  },
  {
    label: 'Excerpt',
    placeholder: 'Enter the excerpt...',
    property: 'excerpt',
    className: 'is-6',
    inputType: InputType.Text,
    required: true
  },
  {
    label: 'Content',
    placeholder: 'Enter the content...',
    property: 'content',
    className: 'is-12',
    inputType: InputType.RichText,
    required: true
  },
  {
    label: 'Image Gallery',
    placeholder: 'Upload an image of the tool',
    property: 'image',
    inputType: InputType.ImageGallery,
    className: 'is-6'
  },
  {
    label: 'WebsiteLink',
    placeholder: 'Enter the websiteLink...',
    property: 'websiteLink',
    className: 'is-6',
    inputType: InputType.Text
  },
  {
    label: 'GitHub Link',
    placeholder: 'Enter the gitHub Link...',
    property: 'githubLink',
    className: 'is-6',
    inputType: InputType.Text
  },
  {
    label: 'GitLab Link',
    placeholder: 'Enter the gitLab Link...',
    property: 'gitLabLink',
    className: 'is-6',
    inputType: InputType.Text
  },
  {
    label: 'HuggingFace Link',
    placeholder: 'Enter the HuggingFace Link...',
    property: 'huggingFaceLink',
    inputType: InputType.Text
  },
  {
    label: 'Tool Package',
    placeholder: 'Enter the Tool Package...',
    property: 'toolPackage',
    className: 'is-6',
    inputType: InputType.Text
  },
  {
    label: 'Slack Link',
    placeholder: 'Enter the Slack Link...',
    property: 'slackLink',
    className: 'is-6',
    inputType: InputType.Text
  },

  {
    label: 'E-mail',
    placeholder: 'Enter the email...',
    property: 'email',
    className: 'is-6',
    inputType: InputType.Text,
    required: true
  },
  {
    label: 'Relation with tool',
    property: 'relationWithTool',
    placeholder: 'Chose Relation with tool...',
    required: true,
    inputType: InputType.Select,
    selectOptions: [
      {
        label: 'Affiliated with tool',
        value: 'AffiliatedWithTool'
      },
      { label: 'Tool user', value: 'ToolUser' },
      {
        label: 'Heard of tool',
        value: 'HeardOfTool'
      }
    ]
  },
  {
    label: 'Duration',
    property: 'duration',
    placeholder: 'Chose the duration of the tool...',
    inputType: InputType.Select,
    selectOptions: [
      {
        label: 'Less than one hour',
        value: '<1 hour'
      },
      {
        label: 'Less than one day',
        value: '<1 day'
      },
      {
        label: 'Less than one week',
        value: '<1 week'
      },
      {
        label: 'Less than one month',
        value: '<1 month'
      },
      {
        label: 'Less than three months',
        value: '<3 months'
      },
      {
        label: 'Less than six months',
        value: '<6 months'
      },
      {
        label: 'Less than one year',
        value: '<1 year'
      },
      {
        label: 'More than one year',
        value: '>1 year'
      }
    ]
  },

  // Relationships.
  {
    label: 'Lifecycle Stages',
    properties: { lifecycleIds: 'lifecycleIds' },
    className: 'is-6',
    searchResources: [lifecycleDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Organisations',
    properties: { organisationIds: 'organisationIds' },
    className: 'is-6',
    searchResources: [organisationDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Objectives',
    properties: {
      objectiveIds: 'objectiveIds'
    },
    className: 'is-6',
    searchResources: [objectiveDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Impacted Stakeholders',
    properties: {
      impactedStakeholderIds: 'impactedStakeholderIds'
    },
    className: 'is-6',
    searchResources: [impactedStakeholderDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Purposes',
    properties: {
      purposeIds: 'purposeIds'
    },
    className: 'is-6',
    searchResources: [purposeDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Usage Rights',
    properties: {
      usageRightIds: 'usageRightIds'
    },
    className: 'is-6',
    searchResources: [usageRightDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Countries',
    properties: {
      countryIds: 'countryIds'
    },
    className: 'is-6',
    searchResources: [countryDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Risk Management Stages',
    properties: {
      riskIds: 'riskIds'
    },
    className: 'is-6',
    searchResources: [riskDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Target Users',
    properties: {
      targetUserIds: 'targetUserIds'
    },
    className: 'is-6',
    searchResources: [targetUserDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Tags',
    properties: {
      tagIds: 'tagIds'
    },
    className: 'is-6',
    searchResources: [tagDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Programming Languages',
    properties: {
      programmingLanguageIds: 'programmingLanguageIds'
    },
    className: 'is-6',
    searchResources: [programmingLanguageDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },

  {
    label: 'Approaches',
    properties: {
      approachIds: 'approachIds'
    },
    className: 'is-6',
    searchResources: [approachDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Maturities',
    properties: {
      maturityIds: 'maturityIds'
    },
    className: 'is-6',
    searchResources: [maturityDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Licenses',
    properties: {
      licenseIds: 'licenseIds'
    },
    className: 'is-6',
    searchResources: [licenseDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Tool Types',
    properties: {
      toolTypeIds: 'toolTypeIds'
    },
    className: 'is-6',
    searchResources: [toolTypeDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Stakeholder Groups',
    properties: {
      stakeholderGroupIds: 'stakeholderGroupIds'
    },
    className: 'is-6',
    searchResources: [stakeholderGroupDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Target Sectors',
    properties: {
      targetSectorIds: 'targetSectorIds'
    },
    className: 'is-6',
    searchResources: [targetSectorDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Target Groups',
    properties: {
      targetGroupIds: 'targetGroupIds'
    },
    className: 'is-6',
    searchResources: [targetGroupDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Geographical Scopes',
    properties: {
      geographicalScopeIds: 'geographicalScopeIds'
    },
    className: 'is-6',
    searchResources: [geographicalScopeDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Technology Platforms',
    properties: {
      technologyPlatformIds: 'technologyPlatformIds'
    },
    className: 'is-6',
    searchResources: [technologyPlatformDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Required skills',
    properties: {
      requiredSkillIds: 'requiredSkillIds'
    },
    className: 'is-6',
    searchResources: [requiredSkillDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'People Involved',
    properties: {
      peopleInvolvedIds: 'peopleInvolvedIds'
    },
    className: 'is-6',
    searchResources: [peopleInvolvedDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Benefits',
    properties: {
      benefitIds: 'benefitIds'
    },
    className: 'is-6',
    searchResources: [benefitDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Validities',
    properties: {
      validityIds: 'validityIds'
    },
    className: 'is-6',
    searchResources: [validityDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Enforcements',
    properties: {
      enforcementIds: 'enforcementIds'
    },
    className: 'is-6',
    searchResources: [enforcementDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  }
]
