import { InputType } from '../../../abacus/enums/input-type.enum'
import { Field } from '../../../abacus/interfaces/field.interface'
import { coreApplicationAreaDefinition } from '../../core-application-area/core-application-area.definition'
import { lifecycleDefinition } from '../../lifecycle/lifecycle.definition'
import { metricTypeDefinition } from '../../metric-type/metric-type.definition'
import { metricUseCaseDefinition } from '../../metric-use-case/metric-use-case.definition'
import { objectiveDefinition } from '../../objective/objective.definition'
import { purposeDefinition } from '../../purpose/purpose.definition'
import { riskDefinition } from '../../risk/risk.definition'
import { targetSectorDefinition } from '../../target-sector/target-sector.definition'
import { targetUserDefinition } from '../../target-user/target-user.definition'
import { usageRightDefinition } from '../../usage-right/usage-right.definition'

export const metricCreateEditFields: Field[] = [
  {
    label: 'Metric name',
    placeholder: 'Enter the metric name...',
    property: 'name',
    className: 'is-6',
    inputType: InputType.Text,
    required: true
  },
  {
    label: 'Content',
    placeholder: 'Enter the content...',
    property: 'content',
    className: 'is-12',
    inputType: InputType.RichText,
    required: true
  },
  {
    label: 'References',
    placeholder: 'Describe references of the metric...',
    property: 'references',
    className: 'is-12',
    inputType: InputType.RichText
  },
  {
    label: 'Website Link',
    placeholder: 'Enter the website link...',
    property: 'websiteLink',
    className: 'is-6',
    inputType: InputType.Text
  },
  {
    label: 'Main Reference',
    property: 'mainReferenceLink',
    className: 'is-6',
    inputType: InputType.Text
  },
  {
    label: 'GitHub Link',
    placeholder: 'Enter the GitHub link...',
    property: 'githubLink',
    className: 'is-6',
    inputType: InputType.Text
  },
  {
    label: 'HuggingFace Link',
    placeholder: 'Enter the HuggingFace Link...',
    property: 'huggingFaceLink',
    className: 'is-6',
    inputType: InputType.Text
  },
  {
    label: 'Citations',
    placeholder: 'Enter the number of citations...',
    property: 'citations',
    className: 'is-6',
    initialValue: 0,
    inputType: InputType.Number
  },
  {
    label: 'Github Stars',
    placeholder: 'Enter the number of stars on the Github repository...',
    property: 'githubStars',
    className: 'is-6',
    initialValue: 0,
    inputType: InputType.Number
  },
  {
    label: 'Github Forks',
    placeholder: 'Enter the number of forks on the Github repository...',
    property: 'githubForks',
    className: 'is-6',
    initialValue: 0,
    inputType: InputType.Number
  },
  {
    label: 'References',
    placeholder: 'Enter the references...',
    property: 'references',
    inputType: InputType.Text
  },
  {
    label: 'Email',
    placeholder: 'Enter the email...',
    property: 'email',
    className: 'is-6',
    inputType: InputType.Text,
    required: true
  },
  {
    label: 'Submitter',
    placeholder: 'Enter the submitter...',
    property: 'submitter',
    className: 'is-6',
    inputType: InputType.Text,
    required: true
  },
  {
    label: 'Metric types',
    properties: {
      metricTypeIds: 'metricTypeIds'
    },
    retrievedItemProperties: {
      metricTypeIds: 'metricTypeIds'
    },
    className: 'is-6',
    searchResources: [metricTypeDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Core application areas',
    properties: {
      coreApplicationAreaIds: 'coreApplicationAreaIds'
    },
    retrievedItemProperties: {
      coreApplicationAreaIds: 'coreApplicationAreaIds'
    },
    className: 'is-6',
    searchResources: [coreApplicationAreaDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Objectives',
    properties: {
      objectiveIds: 'objectiveIds'
    },
    retrievedItemProperties: {
      objectiveIds: 'objectiveIds'
    },
    className: 'is-6',
    searchResources: [objectiveDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Usage Rights',
    properties: {
      usageRightIds: 'usageRightIds'
    },
    retrievedItemProperties: {
      usageRightIds: 'usageRightIds'
    },
    className: 'is-6',
    searchResources: [usageRightDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Risk Management Stages',
    properties: {
      riskIds: 'riskIds'
    },
    className: 'is-6',
    searchResources: [riskDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Purposes',
    properties: {
      purposeIds: 'purposeIds'
    },
    className: 'is-6',
    searchResources: [purposeDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Target users',
    properties: {
      targetUserIds: 'targetUserIds'
    },
    className: 'is-6',
    searchResources: [targetUserDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Lifecycle',
    properties: {
      lifecycleIds: 'lifecycleIds'
    },
    className: 'is-6',
    searchResources: [lifecycleDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Target sectors',
    properties: {
      targetSectorIds: 'targetSectorIds'
    },
    className: 'is-6',
    searchResources: [targetSectorDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Metric use cases',
    properties: {
      metricUseCaseIds: 'metricUseCaseIds'
    },
    retrievedItemProperties: {
      metricUseCaseIds: 'metricUseCaseIds'
    },
    className: 'is-6',
    searchResources: [metricUseCaseDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  }
]
