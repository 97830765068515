import { Validators } from '@angular/forms'
import { InputType } from '../../../../abacus/enums/input-type.enum'
import { Field } from '../../../../abacus/interfaces/field.interface'
import { intergovernmentalOrganisationDefinition } from '../../../intergovernmental-organisation/intergovernmental-organisation.definition'
import { nationalOrganisationDefinition } from '../../../national-organisation/national-organisation.definition'

// Those fields aim to showcase the new inputs developed for this form.
export const step2Fields: Field[] = [
  {
    label: 'Test filtered multi search',
    properties: {
      filteredMultiSearch: 'filteredMultiSearch'
    },
    className: 'is-12',
    searchResources: [nationalOrganisationDefinition],
    filter: intergovernmentalOrganisationDefinition,
    inputType: InputType.MultiSearch
  },
  {
    label: 'Test multi file',
    property: 'testMultiFile',
    className: 'is-12',
    inputType: InputType.MultiFile,
    placeholder: 'Choose a file to upload'
  },
  {
    label: 'TEST Multi image',
    property: 'testMultiImage',
    className: 'is-12',
    inputType: InputType.MultiImage,
    placeholder: 'Choose an image to upload'
  },
  {
    label: 'TEST Checkboxes',
    placeholder: 'Enter the checkboxes...',
    property: 'testMultiText',
    className: 'is-12',
    inputType: InputType.MultiCheckbox,
    selectOptions: [
      {
        label: 'National',
        subLabel: 'Column 1',
        value: 'National'
      },
      {
        label: 'International',
        subLabel: 'Column 1',
        value: 'International'
      },
      {
        label: 'National',
        subLabel: 'Column 2',
        value: 'National'
      },
      {
        label: 'International',
        subLabel: 'Column 2',
        value: 'International'
      }
    ]
  },
  {
    label: 'TEST multi-text',
    placeholder: 'Enter the multi-text...',
    property: 'testMultiText',
    className: 'is-12',
    inputType: InputType.MultiText,
    max: 2
  },
  {
    label: 'TEST EUROS',
    placeholder: 'Enter the budget...',
    property: 'testEuros',
    className: 'is-12',
    inputType: InputType.Euros
  },
  {
    label: 'TEST RADIO',
    placeholder: 'Test radio placeholder',
    property: 'testRadio',
    className: 'is-12',
    inputType: InputType.Radio,
    selectOptions: [
      {
        label: 'National',
        subLabel:
          'This is a random text quite long with lorem ipsum dolor sit amet. This is a random text quite long with lorem ipsum dolor sit amet. This is a random text quite long with lorem ipsum dolor sit amet. This is a random text quite long with lorem ipsum dolor sit amet.',
        value: 'National'
      },
      {
        label: 'International',
        subLabel:
          'This is a random text quite long with lorem ipsum dolor sit amet',
        value: 'International'
      }
    ]
  },
  {
    label: 'TEST RADIO 2',
    placeholder: 'Test radio placeholder',
    property: 'testRadio',
    className: 'is-12',
    inputType: InputType.Radio,
    selectOptions: [
      {
        label: 'International 1',
        subLabel:
          'This is a random text quite long with lorem ipsum dolor sit amet',
        value: 'International'
      },

      {
        label: 'International 2',
        subLabel:
          'This is a random text quite long with lorem ipsum dolor sit amet',
        value: 'International'
      },

      {
        label: 'International 3',
        subLabel:
          'This is a random text quite long with lorem ipsum dolor sit amet',
        value: 'International'
      }
    ]
  },
  {
    id: 'description',
    label: 'test textarea with modal example, word count and html help text',
    property: 'description',
    className: 'is-12',
    inputType: InputType.Textarea,
    htmlHelpText: `<p>This is an example of an HTML content that can be used to provide a short description of the initiative.</p>
    <p>It can include multiple paragraphs, lists, links, and other <strong>HTML elements</strong>.</p>`,
    htmlExample: `
    <p>This is an example of an HTML content that can be used to provide a short description of the initiative.</p>
    <p>It can include multiple paragraphs, lists, links, and other HTML elements.</p>
    `,
    max: 500,
    validators: [Validators.maxLength(500)],
    required: true
  },
  {
    id: 'overview',
    label: 'Overview',
    property: 'overview',
    htmlHelpText: `
      <p>
        The initiative overview is an optional expanded overview of the initiative and its intended or demonstrated outcomes. This could potentially be the same text as an abstract, executive summary, fact sheet, or one-pager about the initiative.
    </p>
    <p>
        Going beyond the short and simple description, this should provide an expanded set of information for the initiative at a high level. In approximately 3-4 paragraphs (maximum 3,000 characters), please share additional information about the initiative, such as:
    </p>
    <ul>
        <li><strong>What</strong> issue does the initiative seek to resolve or what opportunity does it seek to leverage?</li>
        <li><strong>What</strong> are the objectives of the initiative?</li>
        <li><strong>How</strong> is the initiative expected to evolve in the future? For example, how will it be implemented and institutionalised? How could it scale more broadly?</li>
        <li><strong>How</strong> has the initiative <strong>evolved</strong> over time? (especially relevant for periodic updates)</li>
    </ul>
    `,
    htmlExample: `
    <p>This is an example of an HTML content that can be used to provide an overview of the initiative.</p>
    <p>It can include multiple paragraphs, lists, links, and other HTML elements.</p>
    `,
    className: 'is-12',
    inputType: InputType.RichText,
    validators: [Validators.maxLength(3000)],
    max: 3000
  }
]
