import { ActionType } from '../../abacus/enums/action-type.enum'
import { LinkType } from '../../abacus/enums/link-type.enum'
import { ResourceDefinition } from '../../abacus/interfaces/resource-definition.interface'

export const programmingLanguageDefinition: ResourceDefinition = {
  title: 'Programming Languages',
  nameSingular: 'Programming language',
  namePlural: 'Programming languages',
  className: 'ProgrammingLanguage',
  mainIdentifier: 'name',
  slug: 'taxonomies/programming-languages',
  path: 'programming-languages',
  icon: 'icon-grid',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Edit',
      permission: 'editTaxonomies',
      action: (programmingLanguage) => ({
        type: ActionType.Link,
        link: {
          path: `${programmingLanguageDefinition.path}/${programmingLanguage.id}/edit`
        }
      })
    },
    {
      label: 'Delete',
      permission: 'deleteTaxonomies',
      action: (programmingLanguage) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: programmingLanguage,
          definition: programmingLanguageDefinition
        }
      })
    }
  ]
}
