import { Route } from '@angular/router'

import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'

import { TechnologyPlatformCreateEditComponent } from './technology-platform-create-edit/technology-platform-create-edit.component'
import { TechnologyPlatformListComponent } from './technology-platform-list/technology-platform-list.component'

export const technologyPlatformRoutes: Route[] = [
  {
    path: 'technology-platforms',
    component: TechnologyPlatformListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseTaxonomies'
    }
  },
  {
    path: 'technology-platforms/create',
    component: TechnologyPlatformCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addTaxonomies'
    }
  },
  {
    path: 'technology-platforms/:id/edit',
    component: TechnologyPlatformCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editTaxonomies'
    }
  }
]
