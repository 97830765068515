import { ActionType } from '../../abacus/enums/action-type.enum'
import { LinkType } from '../../abacus/enums/link-type.enum'
import { ResourceDefinition } from '../../abacus/interfaces/resource-definition.interface'

export const metricTypeDefinition: ResourceDefinition = {
  title: 'Metric Types',
  nameSingular: 'Metric Type',
  namePlural: 'Metric Types',
  className: 'MetricType',
  mainIdentifier: 'name',
  slug: 'taxonomies/metric-types',
  path: 'metric-types',
  icon: 'icon-grid',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Edit',
      permission: 'editTaxonomies',
      action: (metricType) => ({
        type: ActionType.Link,
        link: {
          path: `${metricTypeDefinition.path}/${metricType.id}/edit`
        }
      })
    },
    {
      label: 'Delete',
      permission: 'deleteToolTypes',
      action: (metricType) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: metricType,
          definition: metricTypeDefinition
        }
      })
    }
  ]
}
