import { Route } from '@angular/router'
import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'

import { ToolCreateEditComponent } from './tool-create-edit/tool-create-edit.component'
import { ToolDetailComponent } from './tool-detail/tool-detail.component'
import { ToolEditForSubmitterComponent } from './tool-edit-for-submitter/tool-edit-for-submitter.component'
import { ToolListComponent } from './tool-list/tool-list.component'

export const toolRoutes: Route[] = [
  {
    path: 'tools',
    component: ToolListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseTools'
    }
  },
  {
    path: 'tools/create',
    component: ToolCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addTools'
    }
  },
  {
    path: 'tools/:id',
    component: ToolDetailComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'readTools'
    }
  },
  {
    path: 'tools/:id/edit',
    component: ToolCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editTools'
    }
  },
  // The id param is actually the token but we keep it consistent to the caseCreateEditComponent.
  {
    path: 'submitter/tools/:id/edit',
    component: ToolEditForSubmitterComponent,
    data: {
      mode: ResourceMode.Edit
    }
  }
]
