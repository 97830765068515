import { ActionType } from '../../abacus/enums/action-type.enum'
import { LinkType } from '../../abacus/enums/link-type.enum'
import { ResourceDefinition } from '../../abacus/interfaces/resource-definition.interface'

export const impactedStakeholderDefinition: ResourceDefinition = {
  title: 'Impacted Stakeholders',
  nameSingular: 'Impacted Stakeholder',
  namePlural: 'Impacted Stakeholders',
  className: 'ImpactedStakeholder',
  mainIdentifier: 'name',
  slug: 'taxonomies/impacted-stakeholders',
  path: 'impacted-stakeholders',
  icon: 'icon-grid',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Edit',
      permission: 'editTaxonomies',
      action: (impactedStakeholder) => ({
        type: ActionType.Link,
        link: {
          path: `${impactedStakeholderDefinition.path}/${impactedStakeholder.id}/edit`
        }
      })
    },
    {
      label: 'Delete',
      permission: 'deleteTaxonomies',
      action: (impactedStakeholder) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: impactedStakeholder,
          definition: impactedStakeholderDefinition
        }
      })
    }
  ]
}
