import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { CaseDetailComponent } from '../../../abacus/components/case-detail.component'
import { ActionType } from '../../../abacus/enums/action-type.enum'
import { Action } from '../../../abacus/interfaces/actions/action.interface'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { ResourceService } from '../../../abacus/services/resource.service'
import { policyInitiativeDefinition } from '../policy-initiative.definition'

@Component({
  selector: 'app-policy-initiative-detail',
  templateUrl: './policy-initiative-detail.component.html',
  styleUrls: ['./policy-initiative-detail.component.scss']
})
export class PolicyInitiativeDetailComponent
  extends CaseDetailComponent
  implements OnInit
{
  definition: ResourceDefinition = policyInitiativeDefinition

  publishAction: Action
  deleteAction: Action

  policyInitiativeRelations = [
    { label: 'Countries', propName: 'nationalOrganisations' },
    {
      label: 'Intergovernmental Organisations',
      propName: 'intergovernmentalOrganisations'
    }
  ]

  constructor(
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute
  ) {
    super(
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  async ngOnInit(): Promise<void> {
    await this.initDetailView()

    this.deleteAction = {
      type: ActionType.Delete,
      delete: {
        itemToDelete: this.item,
        definition: policyInitiativeDefinition,
        redirectTo: '/policy-initiatives'
      }
    }
  }
}
