import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'case-datetime-yield',
  template: `
    {{ datetime | date : 'dd/MM/yy' }}
    <p class="my-0 content is-small has-text-grey-dark">
      {{ datetime | date : 'H:mm:ss' }}
    </p>
  `
})
export class DatetimeYieldComponent {
  @Input() datetime: Date
}
