export enum Duration {
  LessThanOneHour = '<1 hour',
  LessThanOneDay = '<1 day',
  LessThanOneWeek = '<1 week',
  LessThanOneMonth = '<1 month',
  LessThanThreeMonths = '<3 months',
  LessThanSixMonths = '<6 months',
  LessThanOneYear = '<1 year',
  MoreThanOneYear = '>1 year'
}
