import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2
} from '@angular/core'

@Directive({
  selector: '[appHtmlTooltip]'
})
export class HtmlTooltipDirective {
  @Input('appHtmlTooltip') tooltipContent!: string

  private tooltipElement!: HTMLElement

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseenter') onMouseEnter() {
    this.showTooltip()
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hideTooltip()
  }

  private showTooltip() {
    if (!this.tooltipContent) return

    // Create tooltip element
    this.tooltipElement = this.renderer.createElement('div')
    this.tooltipElement.innerHTML = this.tooltipContent // Support HTML content
    this.renderer.appendChild(document.body, this.tooltipElement)

    this.renderer.setStyle(this.tooltipElement, 'position', 'absolute')
    this.renderer.setStyle(this.tooltipElement, 'background', '#142f4e')
    this.renderer.setStyle(this.tooltipElement, 'color', '#fff')
    this.renderer.setStyle(this.tooltipElement, 'padding', '5px 10px')
    this.renderer.setStyle(this.tooltipElement, 'border-radius', '4px')
    this.renderer.setStyle(this.tooltipElement, 'z-index', '1000')
    this.renderer.setStyle(this.tooltipElement, 'pointer-events', 'none')
    this.renderer.setStyle(this.tooltipElement, 'max-width', '320px')
    this.renderer.setStyle(this.tooltipElement, 'font-size', '1.5rem')

    const hostPos = this.el.nativeElement.getBoundingClientRect()

    // Position tooltip
    this.renderer.setStyle(
      this.tooltipElement,
      'top',
      `${hostPos.bottom + window.scrollY}px`
    )
    this.renderer.setStyle(
      this.tooltipElement,
      'left',
      `${hostPos.left + window.scrollX}px`
    )
  }

  private hideTooltip() {
    if (this.tooltipElement) {
      this.renderer.removeChild(document.body, this.tooltipElement)
      this.tooltipElement = null!
    }
  }
}
