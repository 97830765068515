import { Route } from '@angular/router'

import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'

import { StakeholderGroupCreateEditComponent } from './stakeholder-group-create-edit/stakeholder-group-create-edit.component'
import { StakeholderGroupListComponent } from './stakeholder-group-list/stakeholder-group-list.component'

export const stakeholderGroupRoutes: Route[] = [
  {
    path: 'stakeholder-groups',
    component: StakeholderGroupListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseTaxonomies'
    }
  },
  {
    path: 'stakeholder-groups/create',
    component: StakeholderGroupCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addTaxonomies'
    }
  },
  {
    path: 'stakeholder-groups/:id/edit',
    component: StakeholderGroupCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editTaxonomies'
    }
  }
]
