import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core'
import { ValidatorFn, Validators } from '@angular/forms'
import { CKEditor5, ChangeEvent } from '@ckeditor/ckeditor5-angular'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import { CaseInput } from '../../../interfaces/case-input.interface'

@Component({
  selector: 'case-rich-text-input',
  templateUrl: './rich-text-input.component.html',
  styleUrls: ['./rich-text-input.component.scss']
})
export class RichTextInputComponent implements CaseInput, OnChanges {
  @Input() label: string
  @Input() initialValue: string
  @Input() placeholder: string
  @Input() helpText: string
  @Input() htmlHelpText?: string
  @Input() htmlTooltip?: string
  @Input() htmlExample?: string
  @Input() showErrors = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string
  @Input() max?: number // Max number of characters. Redundant with validators, but useful as impossible to get the max length from the validators.

  @Output() valueChanged: EventEmitter<string> = new EventEmitter()

  editor = ClassicEditor
  editorConfig: CKEditor5.Config

  content: string
  required: boolean
  showExampleModal = false

  ngOnChanges(changes: SimpleChanges) {
    // Prevent value from being reset if showErrors changes.
    if (
      Object.keys(changes).length === 1 &&
      Object.keys(changes)[0] === 'showErrors'
    ) {
      return
    }

    this.editorConfig = {
      toolbar: ['bold', 'italic', 'Link', '|', 'bulletedList'],
      link: {
        addTargetToExternalLinks: true
      },
      placeholder: this.placeholder
    }

    this.content = this.initialValue || ''
    this.required = this.validators.includes(Validators.required)

    if (this.max) {
      this.validators.push(Validators.maxLength(this.max))
    }
  }

  onChange(event: ChangeEvent) {
    this.content = event.editor.getData()
    this.valueChanged.emit(this.content)
  }

  // Click outside closes modal.
  @HostListener('document:click', ['$event.target'])
  clickOut(eventTarget) {
    if (
      this.showExampleModal &&
      eventTarget.className.includes('modal-background')
    ) {
      this.showExampleModal = false
    }
  }
}
