import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms'

import { CaseInput } from '../../../interfaces/case-input.interface'
import { environment } from '../../../../../environments/environment'
import { UploadService } from '../../../services/upload.service'
import { FlashMessageService } from '../../../services/flash-message.service'

@Component({
  selector: 'case-multi-file-input',
  templateUrl: './multi-file-input.component.html',
  styleUrls: ['./multi-file-input.component.scss']
})
export class MultiFileInputComponent implements OnChanges, CaseInput {
  @Input() label: string
  @Input() initialValue: string
  @Input() placeholder: string
  @Input() helpText: string
  @Input() resourceName: string
  @Input() showErrors = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string
  @Input() max: number

  ngOnInit(): void {}

  @Output() valueChanged: EventEmitter<string> = new EventEmitter()

  @ViewChild('fileInput', { static: false }) fileInputEl: ElementRef

  storagePath: string = environment.storagePath

  form: UntypedFormGroup

  required: boolean
  loading: boolean

  constructor(
    private formBuilder: UntypedFormBuilder,
    private uploadService: UploadService,
    private flashMessageService: FlashMessageService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    // Prevent value from being reset if showErrors changes.
    if (
      Object.keys(changes).length === 1 &&
      Object.keys(changes)[0] === 'showErrors'
    ) {
      return
    }

    this.form = this.formBuilder.group({
      fileArray: this.formBuilder.array([])
    })

    this.required = this.validators.includes(Validators.required)
  }

  onKeyup(newValue: string) {
    this.valueChanged.emit(newValue)
  }

  // Upload file and update value.
  addFile() {
    this.loading = true
    this.uploadService
      .uploadFile(
        this.resourceName,
        this.fileInputEl.nativeElement.files.item(0)
      )
      .subscribe(
        (res: { path: string }) => {
          this.loading = false

          this.fileArray.push(this.formBuilder.control(res.path))

          this.valueChanged.emit(this.form.get('fileArray').value)
        },
        (err) => {
          this.loading = false
          this.flashMessageService.error(
            'There was an error uploading your file.'
          )
        }
      )
  }

  removeFile(index: number) {
    this.fileArray.removeAt(index)

    this.valueChanged.emit(this.form.get('fileArray').value)

    setTimeout(() => {
      this.fileInputEl.nativeElement.focus(), 0
    })
  }

  get fileArray(): UntypedFormArray {
    return this.form.get('fileArray') as UntypedFormArray
  }
}
