import { Route } from '@angular/router'
import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'
import { CoreApplicationListComponent } from './core-application-area-list/core-application-area-list.component'
import { CoreApplicationAreaCreateEditComponent } from './core-application-area-create-edit/corea-application-area-create-edit.component'

export const coreApplicationAreaRoutes: Route[] = [
  {
    path: 'core-application-areas',
    component: CoreApplicationListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseTaxonomies'
    }
  },
  {
    path: 'core-application-areas/create',
    component: CoreApplicationAreaCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addTaxonomies'
    }
  },
  {
    path: 'core-application-areas/:id/edit',
    component: CoreApplicationAreaCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editTaxonomies'
    }
  }
]
