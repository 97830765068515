import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { CaseCreateEditComponent } from '../../../abacus/components/case-create-edit.component'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { Field } from '../../../abacus/interfaces/field.interface'
import { ResourceService } from '../../../abacus/services/resource.service'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { toolCreateEditFields } from '../tool-create-edit/tool-create-edit.fields'
import { toolDefinition } from '../tool.definition'

@Component({
  templateUrl:
    './../../../../assets/templates/case-create-edit-template-without-permissions.html'
})
export class ToolEditForSubmitterComponent
  extends CaseCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = Object.assign(toolDefinition, {
    slug: 'tools/t'
  })

  fields: Field[] = toolCreateEditFields

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    private componentFlashMessageService: FlashMessageService,
    private componentResourceService: ResourceService,
    private componentActivatedRoute: ActivatedRoute
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  async ngOnInit() {
    await this.initCreateEditView()
  }

  submit() {
    if (this.form.invalid) {
      this.showErrors = true

      return this.componentFlashMessageService.error(
        `Invalid form. Please check fields in red.`
      )
    }

    const token: string = this.componentActivatedRoute?.snapshot?.params?.id

    this.loading = true
    this.componentResourceService
      .update(this.definition.slug, token, this.form.value)
      .then(
        (res: { id: number }) => {
          this.componentFlashMessageService.success(
            `The ${this.definition.nameSingular} has been updated successfully. Return to the OECD.AI portal to see it updated.`
          )
          this.loading = false
        },
        (err: HttpErrorResponse) => {
          this.loading = false
          this.componentFlashMessageService.error(
            err?.error?.message
              ? err.error.message
              : `An error occurred. The resource could not be saved.`
          )
        }
      )
  }
}
