import { InputType } from '../../../abacus/enums/input-type.enum'
import { Field } from '../../../abacus/interfaces/field.interface'

import { metricDefinition } from '../../metric/metric.definition'
import { objectiveDefinition } from '../../objective/objective.definition'
import { purposeDefinition } from '../../purpose/purpose.definition'
import { riskDefinition } from '../../risk/risk.definition'
import { targetSectorDefinition } from '../../target-sector/target-sector.definition'

export const metricUseCaseCreateEditFields: Field[] = [
  {
    label: 'Metric use case name',
    placeholder: 'Enter the metric use case name...',
    property: 'name',
    className: 'is-6',
    inputType: InputType.Text,
    required: true
  },
  {
    label: 'Metric use case description',
    placeholder: 'Enter the metric use case description...',
    property: 'content',
    className: 'is-12',
    inputType: InputType.RichText,
    required: true
  },
  {
    label: 'Main Reference Link',
    placeholder: 'Enter the main reference link...',
    property: 'mainReferenceLink',
    className: 'is-6',
    inputType: InputType.Text
  },
  {
    label: 'Website Link',
    placeholder: 'Enter the website link...',
    property: 'websiteLink',
    className: 'is-6',
    inputType: InputType.Text
  },
  {
    label: 'GitHub Link',
    placeholder: 'Enter the GitHub link...',
    property: 'githubLink',
    className: 'is-6',
    inputType: InputType.Text
  },
  {
    label: 'HuggingFace Link',
    placeholder: 'Enter the HuggingFace Link...',
    property: 'huggingFaceLink',
    className: 'is-6',
    inputType: InputType.Text
  },
  {
    label: 'Email',
    placeholder: 'Enter the email...',
    property: 'email',
    className: 'is-6',
    inputType: InputType.Text,
    required: true
  },
  {
    label: 'Submitter',
    placeholder: 'Enter the submitter...',
    property: 'submitter',
    className: 'is-6',
    inputType: InputType.Text
  },
  // Relations.
  {
    label: 'Metrics',
    properties: { metricIds: 'metricIds' },
    retrievedItemProperties: {
      metricIds: 'metricIds'
    },
    className: 'is-6',
    searchResources: [metricDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: false
  },
  {
    label: 'Purposes',
    properties: {
      purposeIds: 'purposeIds'
    },
    retrievedItemProperties: {
      purposeIds: 'purposeIds'
    },
    className: 'is-6',
    searchResources: [purposeDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Target sectors',
    properties: {
      targetSectorIds: 'targetSectorIds'
    },
    retrievedItemProperties: {
      targetSectorIds: 'targetSectorIds'
    },
    className: 'is-6',
    searchResources: [targetSectorDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Objectives',
    properties: {
      objectiveIds: 'objectiveIds'
    },
    retrievedItemProperties: {
      objectiveIds: 'objectiveIds'
    },
    className: 'is-6',
    searchResources: [objectiveDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  },
  {
    label: 'Risk Management Stages',
    properties: {
      riskIds: 'riskIds'
    },
    className: 'is-6',
    searchResources: [riskDefinition],
    inputType: InputType.MultiSearch,
    showCreateButton: true
  }
]
