import { Route } from '@angular/router'

import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'

import { PeopleInvolvedCreateEditComponent } from './people-involved-create-edit/people-involved-create-edit.component'
import { PeopleInvolvedListComponent } from './people-involved-list/people-involved-list.component'

export const peopleInvolvedRoutes: Route[] = [
  {
    path: 'people-involveds',
    component: PeopleInvolvedListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseTaxonomies'
    }
  },
  {
    path: 'people-involveds/create',
    component: PeopleInvolvedCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addTaxonomies'
    }
  },
  {
    path: 'people-involveds/:id/edit',
    component: PeopleInvolvedCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editTaxonomies'
    }
  }
]
