import { Route } from '@angular/router'
import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'

import { MetricTypeCreateEditComponent } from './metric-type-create-edit/metric-type-create-edit.component'
import { MetricTypeListComponent } from './metric-type-list/metric-type-list.component'

export const metricTypeRoutes: Route[] = [
  {
    path: 'metric-types',
    component: MetricTypeListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseTaxonomies'
    }
  },
  {
    path: 'metric-types/create',
    component: MetricTypeCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addTaxonomies'
    }
  },
  {
    path: 'metric-types/:id/edit',
    component: MetricTypeCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editTaxonomies'
    }
  }
]
