import { InputType } from '../../../../abacus/enums/input-type.enum'
import { Field } from '../../../../abacus/interfaces/field.interface'

export const step3Fields: Field[] = [
  {
    label: 'Optional field step 3',
    placeholder: 'Enter the name...',
    property: 'nameStep3',
    className: 'is-6',
    inputType: InputType.Text
  }
]
