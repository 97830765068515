import { Route } from '@angular/router'

import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'

import { BenefitCreateEditComponent } from './benefit-create-edit/benefit-create-edit.component'
import { BenefitListComponent } from './benefit-list/benefit-list.component'

export const benefitRoutes: Route[] = [
  {
    path: 'benefits',
    component: BenefitListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseTaxonomies'
    }
  },
  {
    path: 'benefits/create',
    component: BenefitCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addTaxoomies'
    }
  },
  {
    path: 'benefits/:id/edit',
    component: BenefitCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editTaxoomies'
    }
  }
]
