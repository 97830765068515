import { Route } from '@angular/router'
import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'

import { MetricUseCaseCreateEditComponent } from './metric-use-case-create-edit/metric-use-case-create-edit.component'
import { MetricUseCaseDetailComponent } from './metric-use-case-detail/metric-use-case-detail.component'
import { MetricUseCaseEditForSubmitterComponent } from './metric-use-case-edit-for-submitter/metric-use-case-edit-for-submitter.component'
import { MetricUseCaseListComponent } from './metric-use-case-list/metric-use-case-list.component'

export const metricUseCaseRoutes: Route[] = [
  {
    path: 'metric-use-cases',
    component: MetricUseCaseListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseMetricUseCases'
    }
  },
  {
    path: 'metric-use-cases/create',
    component: MetricUseCaseCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addMetricUseCases'
    }
  },
  {
    path: 'metric-use-cases/:id',
    component: MetricUseCaseDetailComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'readMetricUseCases'
    }
  },
  {
    path: 'metric-use-cases/:id/edit',
    component: MetricUseCaseCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editMetricUseCases'
    }
  },
  {
    path: 'submitter/metric-use-cases/:id/edit',
    component: MetricUseCaseEditForSubmitterComponent,
    data: {
      mode: ResourceMode.Edit
    }
  }
]
