export enum YieldType {
  AnalogProgressBar,
  Color,
  Check,
  Icon,
  Currency,
  Date,
  Datetime,
  Download,
  FileIcon,
  Image,
  ProgressBar,
  Switch,
  Text
}
