import { Route } from '@angular/router'

import { ResourceMode } from '../../abacus/enums/resource-mode.enum'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { PermissionGuard } from '../../abacus/guards/permission.guard'

import { NationalOrganisationCreateEditComponent } from './national-organisation-create-edit/national-organisation-create-edit.component'
import { NationalOrganisationListComponent } from './national-organisation-list/national-organisation-list.component'

export const nationalOrganisationRoutes: Route[] = [
  {
    path: 'national-organisations',
    component: NationalOrganisationListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browsePolicyInitiativeTaxonomies'
    }
  },
  {
    path: 'national-organisations/create',
    component: NationalOrganisationCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addPolicyInitiativeTaxonomies'
    }
  },
  {
    path: 'national-organisations/:id/edit',
    component: NationalOrganisationCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editPolicyInitiativeTaxonomies'
    }
  }
]
