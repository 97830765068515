import { Route } from '@angular/router'

import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'

import { InitiativeTypeCreateEditComponent } from './initiative-type-create-edit/initiative-type-create-edit.component'
import { InitiativeTypeListComponent } from './initiative-type-list/initiative-type-list.component'

export const initiativeTypeRoutes: Route[] = [
  {
    path: 'initiative-types',
    component: InitiativeTypeListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browsePolicyInitiativeTaxonomies'
    }
  },
  {
    path: 'initiative-types/create',
    component: InitiativeTypeCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addPolicyInitiativeTaxonomies'
    }
  },
  {
    path: 'initiative-types/:id/edit',
    component: InitiativeTypeCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editPolicyInitiativeTaxonomies'
    }
  }
]
