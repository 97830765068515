import { YieldType } from '../../abacus/enums/yield-type.enum'
import { Yield } from '../../abacus/interfaces/yield.interface'

export const metricYields: Yield[] = [
  {
    label: 'Name',
    property: 'name'
  },
  {
    label: 'Submitter',
    property: 'submitter'
  },
  {
    label: 'Submission date',
    property: 'createdAt',
    type: YieldType.Date
  },
  {
    label: 'Publication date',
    property: 'publishedAt',
    type: YieldType.Date
  },
  {
    label: '',
    property: 'rejectReason',
    icon: 'icon-message-square',
    type: YieldType.Icon
  }
]
