import { Route } from '@angular/router'

import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'

import { IntergovernmentalOrganisationCreateEditComponent } from './intergovernmental-organisation-create-edit/intergovernmental-organisation-create-edit.component'
import { IntergovernmentalOrganisationListComponent } from './intergovernmental-organisation-list/intergovernmental-organisation-list.component'

export const intergovernmentalOrganisationRoutes: Route[] = [
  {
    path: 'intergovernmental-organisations',
    component: IntergovernmentalOrganisationListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browsePolicyInitiativeTaxonomies'
    }
  },
  {
    path: 'intergovernmental-organisations/create',
    component: IntergovernmentalOrganisationCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addPolicyInitiativeTaxonomies'
    }
  },
  {
    path: 'intergovernmental-organisations/:id/edit',
    component: IntergovernmentalOrganisationCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editPolicyInitiativeTaxonomies'
    }
  }
]
