import { Route } from '@angular/router'

import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'

import { AIPrincipleCreateEditComponent } from './ai-principle-create-edit/ai-principle-create-edit.component'
import { AIPrincipleListComponent } from './ai-principle-list/ai-principle-list.component'

export const aiPrincipleRoutes: Route[] = [
  {
    path: 'ai-principles',
    component: AIPrincipleListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browsePolicyInitiativeTaxonomies'
    }
  },
  {
    path: 'ai-principles/create',
    component: AIPrincipleCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addPolicyInitiativeTaxonomies'
    }
  },
  {
    path: 'ai-principles/:id/edit',
    component: AIPrincipleCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editPolicyInitiativeTaxonomies'
    }
  }
]
