import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms'

import { CaseInput } from '../../../interfaces/case-input.interface'
import { environment } from '../../../../../environments/environment'
import { UploadService } from '../../../services/upload.service'
import { FlashMessageService } from '../../../services/flash-message.service'
@Component({
  selector: 'case-multi-image-input',
  templateUrl: './multi-image-input.component.html',
  styleUrls: ['./multi-image-input.component.scss']
})
export class MultiImageInputComponent implements OnChanges, CaseInput {
  @Input() label: string
  @Input() initialValue: string
  @Input() placeholder: string
  @Input() helpText: string
  @Input() resourceName: string
  @Input() showErrors = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string
  @Input() max: number

  @Output() valueChanged: EventEmitter<string> = new EventEmitter()

  @ViewChild('imageInput', { static: false }) imageInputEl: ElementRef

  storagePath: string = environment.storagePath

  form: UntypedFormGroup

  required: boolean
  loading: boolean

  constructor(
    private formBuilder: UntypedFormBuilder,
    private uploadService: UploadService,
    private flashMessageService: FlashMessageService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    // Prevent value from being reset if showErrors changes.
    if (
      Object.keys(changes).length === 1 &&
      Object.keys(changes)[0] === 'showErrors'
    ) {
      return
    }

    this.form = this.formBuilder.group({
      imageArray: this.formBuilder.array([])
    })

    this.required = this.validators.includes(Validators.required)
  }

  onKeyup(newValue: string) {
    this.valueChanged.emit(newValue)
  }

  // Upload image and update value.
  addImage() {
    this.loading = true
    this.uploadService
      .uploadImage(
        this.resourceName,
        this.imageInputEl.nativeElement.files.item(0)
      )
      .subscribe(
        (res: { path: string }) => {
          this.loading = false

          this.imageArray.push(this.formBuilder.control(res.path))

          this.valueChanged.emit(this.form.get('imageArray').value)
        },
        (err) => {
          this.loading = false
          this.flashMessageService.error(
            'There was an error uploading your image.'
          )
        }
      )
  }

  removeImage(index: number) {
    this.imageArray.removeAt(index)

    this.valueChanged.emit(this.form.get('imageArray').value)

    setTimeout(() => {
      this.imageInputEl.nativeElement.focus(), 0
    })
  }

  get imageArray(): UntypedFormArray {
    return this.form.get('imageArray') as UntypedFormArray
  }
}
