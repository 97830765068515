import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { caseListTemplate } from '../../../abacus/templates/case-list.template'
import { CaseListComponent } from '../../../abacus/components/case-list.component'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { Yield } from '../../../abacus/interfaces/yield.interface'
import { Filter } from '../../../abacus/interfaces/filter.interface'
import { InputType } from '../../../abacus/enums/input-type.enum'
import { ResourceService } from '../../../abacus/services/resource.service'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { AuthService } from '../../../abacus/services/auth.service'
import { FilterService } from '../../../abacus/services/filter.service'

import { toolUseCaseDefinition } from '../tool-use-case.definition'
import { toolUseCaseYields } from '../tool-use-case.yields'
import { Status } from '../../../common/enums/status.enum'

@Component({ template: caseListTemplate })
export class ToolUseCaseListComponent
  extends CaseListComponent
  implements OnInit
{
  definition: ResourceDefinition = toolUseCaseDefinition
  yields: Yield[] = toolUseCaseYields

  filters: Filter[] = [
    {
      label: 'Search a tool use case',
      properties: {
        toolUseCaseIds: 'toolUseCaseIds'
      },
      inputType: InputType.MultiSearch,
      searchResources: [toolUseCaseDefinition],
      className: 'is-6'
    },
    {
      label: 'Status',
      property: 'status',
      placeholder: 'All status',
      inputType: InputType.Select,
      selectOptions: [
        {
          label: 'Published',
          value: Status.Published
        },
        {
          label: 'Pending',
          value: Status.Pending
        },
        {
          label: 'Rejected',
          value: Status.Rejected
        }
      ]
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    authService: AuthService,
    filterService: FilterService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService,
      authService,
      filterService
    )
  }

  ngOnInit() {
    this.initListView()
  }
}
