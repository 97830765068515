import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms'

import { CaseInput } from '../../../interfaces/case-input.interface'

@Component({
  selector: 'case-multi-text-input',
  templateUrl: './multi-text-input.component.html',
  styleUrls: ['./multi-text-input.component.scss']
})
export class MultiTextInputComponent implements OnChanges, CaseInput {
  @Input() label: string
  @Input() initialValue: string
  @Input() placeholder: string
  @Input() helpText: string
  @Input() showErrors = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string
  @Input() max: number

  @Output() valueChanged: EventEmitter<string> = new EventEmitter()

  @ViewChild('input') input: ElementRef

  form: UntypedFormGroup
  required: boolean

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnChanges(changes: SimpleChanges) {
    // Prevent value from being reset if showErrors changes.
    if (
      Object.keys(changes).length === 1 &&
      Object.keys(changes)[0] === 'showErrors'
    ) {
      return
    }

    this.form = this.formBuilder.group({
      text: '',
      textArray: this.formBuilder.array([])
    })

    this.required = this.validators.includes(Validators.required)
  }

  onKeyup(newValue: string) {
    this.valueChanged.emit(newValue)
  }

  addText() {
    const newValue = this.form.get('text').value
    this.textArray.push(this.formBuilder.control(newValue))

    this.valueChanged.emit(this.form.get('textArray').value)

    // Scroll down and focus on the input.
    this.form.get('text').reset()
    window.scrollBy({
      top: this.input.nativeElement.offsetHeight,
      behavior: 'smooth'
    })
    this.input.nativeElement.focus()
  }

  removeText(index: number) {
    this.textArray.removeAt(index)

    this.valueChanged.emit(this.form.get('textArray').value)

    setTimeout(() => {
      this.input.nativeElement.focus(), 0
    })
  }

  get textArray(): UntypedFormArray {
    return this.form.get('textArray') as UntypedFormArray
  }
}
