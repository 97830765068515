import { Route } from '@angular/router'
import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'

import { MetricCreateEditComponent } from './metric-create-edit/metric-create-edit.component'
import { MetricDetailComponent } from './metric-detail/metric-detail.component'
import { MetricEditForSubmitterComponent } from './metric-edit-for-submitter/metric-edit-for-submitter.component'
import { MetricListComponent } from './metric-list/metric-list.component'

export const metricRoutes: Route[] = [
  {
    path: 'metrics',
    component: MetricListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseMetrics'
    }
  },
  {
    path: 'metrics/create',
    component: MetricCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addMetrics'
    }
  },
  {
    path: 'metrics/:id',
    component: MetricDetailComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'readMetrics'
    }
  },
  {
    path: 'metrics/:id/edit',
    component: MetricCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editMetrics'
    }
  },
  {
    path: 'submitter/metrics/:id/edit',
    component: MetricEditForSubmitterComponent,
    data: {
      mode: ResourceMode.Edit
    }
  }
]
