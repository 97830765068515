import { Route } from '@angular/router'

import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'

import { GeographicalScopeCreateEditComponent } from './geographical-scope-create-edit/geographical-scope-create-edit.component'
import { GeographicalScopeListComponent } from './geographical-scope-list/geographical-scope-list.component'

export const geographicalScopeRoutes: Route[] = [
  {
    path: 'geographical-scopes',
    component: GeographicalScopeListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseTaxonomies'
    }
  },
  {
    path: 'geographical-scopes/create',
    component: GeographicalScopeCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addTaxonomies'
    }
  },
  {
    path: 'geographical-scopes/:id/edit',
    component: GeographicalScopeCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editTaxonomies'
    }
  }
]
