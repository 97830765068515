import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core'
import { ValidatorFn, Validators } from '@angular/forms'

import { HTMLInputEvent } from 'src/app/abacus/interfaces/html-input-event.interface'
import { environment } from '../../../../../environments/environment'
import { CaseInput } from '../../../interfaces/case-input.interface'
import { FlashMessageService } from '../../../services/flash-message.service'
import { UploadService } from '../../../services/upload.service'

@Component({
  selector: 'case-image-gallery-input',
  templateUrl: './image-gallery-input.component.html',
  styleUrls: ['./image-gallery-input.component.scss']
})
export class ImageGalleryInputComponent implements CaseInput, OnChanges {
  @Input() label: string
  @Input() placeholder: string
  @Input() helpText: string
  @Input() resourceName: string
  @Input() initialValue: string
  @Input() showErrors = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string
  @Input() accept = '*'

  @ViewChild('imageInput', { static: false }) imageInputEl: ElementRef

  @Output() valueChanged: EventEmitter<string> = new EventEmitter()

  storagePath: string = environment.storagePath
  selectedImage: string

  showImageGalleryModal = false

  fileContent: any
  required: boolean
  loading: boolean

  images: string[] = [
    'default/8dab1dizlfgyc79d',
    'default/8dab1dizlfgycfof',
    'default/8dab1dizlfgyj872',
    'default/8dab1dizlfgyj2ao',
    'default/8dab1dizlfgyiuwe',
    'default/8dab1dizlfgyiqds',
    'default/8dab1dizlfgyikt2',
    'default/8dab1dizlfgyiepb',
    'default/8dab1dizlfgyi4vb',
    'default/8dab1dizlfgyhr0t',
    'default/8dab1dizlfgyhcri',
    'default/8dab1dizlfgyh1mq',
    'default/8dab1dizlfgygx1a',
    'default/8dab1dizlfgygn21',
    'default/8dab1dizlfgygii4',
    'default/8dab1dizlfgyfuov',
    'default/8dab1dizlfgyfqhf',
    'default/8dab1dizlfgyflli',
    'default/8dab1dizlfgyfg1j',
    'default/8dab1dizlfgyfbs8',
    'default/8dab1dizlfgyf6yd',
    'default/8dab1dizlfgyf2wd',
    'default/8dab1dizlfgyexl0',
    'default/8dab1dizlfgyer48',
    'default/8dab1dizlfgyekrw',
    'default/8dab1dizlfgyeeen',
    'default/8dab1dizlfgydv9x',
    'default/8dab1dizlfgye7c2',
    'default/8dab1dizlfgydkz8',
    'default/8dab1dizlfgyddnb',
    'default/8dab1dizlfgyd9j6',
    'default/8dab1dizlfgyd2w2',
    'default/8dab1dizlfgycxic',
    'default/8dab1dizlfgyctja',
    'default/8dab1dizlfgycpbh',
    'default/8dab1dizlfgzdcrs',
    'default/8dab1dizlfgzdhcn',
    'default/8dab1dizlfgzdku0',
    'default/8dab1dizlfgzdntq',
    'default/8dab1dizlfgzdrs5',
    'default/8dab1dizlfgzduwa',
    'default/8dab1dizlfgzdxim',
    'default/8dab1dizlfgze0aq',
    'default/8dab1dizlfgze6ak',
    'default/8dab1dizlfgze9b9',
    'default/8dab1dizlfgzecct',
    'default/8dab1dizlfgzecct',
    'default/8dab1dizlfgzeg2h'
  ]

  constructor(
    private uploadService: UploadService,
    private flashMessageService: FlashMessageService
  ) {}

  ngOnChanges() {
    this.images = this.getRandomImages(this.images)

    this.selectedImage = this.initialValue || null

    this.required = this.validators.includes(Validators.required)
  }

  selectImage(image: string) {
    this.selectedImage = image
  }

  // Upload image and update value.
  imageInputEvent(imageInput: HTMLInputEvent) {
    this.loading = true
    this.fileContent = this.imageInputEl.nativeElement.files.item(0)
    this.uploadService
      .uploadImage(this.resourceName, this.fileContent)
      .subscribe(
        (res: { path: string }) => {
          this.loading = false
          this.selectedImage = res.path
          this.valueChanged.emit(this.selectedImage)
        },
        (err) => {
          this.loading = false
          this.flashMessageService.error(
            'There was an error uploading your image.'
          )
        }
      )
  }

  confirmSelection() {
    this.loading = false
    this.showImageGalleryModal = false
    this.valueChanged.emit(this.selectedImage)
  }

  clear() {
    this.showImageGalleryModal = false
    delete this.selectedImage
    this.valueChanged.emit(null)
  }

  private getRandomImages(imagePaths: string[]): string[] {
    const halfLength = Math.ceil(imagePaths.length / 2)
    const randomImages: string[] = []

    while (randomImages.length < halfLength) {
      const randomIndex = Math.floor(Math.random() * imagePaths.length)
      if (!randomImages.includes(imagePaths[randomIndex])) {
        randomImages.push(imagePaths[randomIndex])
      }
    }

    return randomImages
  }
}
