import { Route } from '@angular/router'

import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'

import { ObjectiveCreateEditComponent } from './objective-create-edit/objective-create-edit.component'
import { ObjectiveListComponent } from './objective-list/objective-list.component'

export const objectiveRoutes: Route[] = [
  {
    path: 'objectives',
    component: ObjectiveListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseTaxonomies'
    }
  },
  {
    path: 'objectives/create',
    component: ObjectiveCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addTaxonomies'
    }
  },
  {
    path: 'objectives/:id/edit',
    component: ObjectiveCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editTaxonomies'
    }
  }
]
