import { ActionType } from '../../abacus/enums/action-type.enum'
import { LinkType } from '../../abacus/enums/link-type.enum'
import { ResourceDefinition } from '../../abacus/interfaces/resource-definition.interface'

export const maturityDefinition: ResourceDefinition = {
  title: 'Maturities',
  nameSingular: 'Maturity',
  namePlural: 'Maturities',
  className: 'Maturity',
  mainIdentifier: 'name',
  slug: 'taxonomies/maturities',
  path: 'maturities',
  icon: 'icon-grid',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Edit',
      permission: 'editTaxonomies',
      action: (maturity) => ({
        type: ActionType.Link,
        link: {
          path: `${maturityDefinition.path}/${maturity.id}/edit`
        }
      })
    },
    {
      label: 'Delete',
      permission: 'deleteTaxonomies',
      action: (maturity) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: maturity,
          definition: maturityDefinition
        }
      })
    }
  ]
}
