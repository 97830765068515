import { Route } from '@angular/router'

import { ResourceMode } from '../../abacus/enums/resource-mode.enum'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { PolicyInitiativeCreateEditComponent } from './policy-initiative-create-edit/policy-initiative-create-edit.component'
import { PolicyInitiativeDetailComponent } from './policy-initiative-detail/policy-initiative-detail.component'
import { PolicyInitiativeListComponent } from './policy-initiative-list/policy-initiative-list.component'

export const policyInitiativeRoutes: Route[] = [
  {
    path: 'policy-initiatives',
    component: PolicyInitiativeListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: ['browsePolicyInitiatives', 'browseOwnPolicyInitiatives']
    }
  },
  {
    path: 'policy-initiatives/create',
    component: PolicyInitiativeCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permissions: ['addPolicyInitiatives', 'addOwnPolicyInitiatives']
    }
  },
  {
    path: 'policy-initiatives/:id',
    component: PolicyInitiativeDetailComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: ['readPolicyInitiatives', 'readOwnPolicyInitiatives']
    }
  },
  {
    path: 'policy-initiatives/:id/edit',
    component: PolicyInitiativeCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permissions: ['editPolicyInitiatives', 'editOwnPolicyInitiatives']
    }
  }
]
