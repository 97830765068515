import { ActionType } from '../../abacus/enums/action-type.enum'
import { LinkType } from '../../abacus/enums/link-type.enum'
import { ResourceDefinition } from '../../abacus/interfaces/resource-definition.interface'

export const policyInitiativeDefinition: ResourceDefinition = {
  title: 'Policy initiatives',
  nameSingular: 'policy initiative',
  namePlural: 'policy initiatives',
  className: 'PolicyInitiative',
  mainIdentifier: 'title',
  slug: 'policy-initiatives',
  path: 'policy-initiatives',
  icon: 'icon-layers',
  hasDetailPage: true,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.DETAIL,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Edit',
      permission: 'editPolicyInitiatives',
      action: (policyInitiative) => ({
        type: ActionType.Link,
        link: {
          path: `${policyInitiativeDefinition.path}/${policyInitiative.id}/edit`
        }
      })
    },
    {
      label: 'Delete',
      permission: 'deleteOwnPolicyInitiatives',
      action: (policyInitiative) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: policyInitiative,
          definition: policyInitiativeDefinition
        }
      })
    }
  ]
}
