import { ActionType } from '../../abacus/enums/action-type.enum'
import { LinkType } from '../../abacus/enums/link-type.enum'
import { ResourceDefinition } from '../../abacus/interfaces/resource-definition.interface'

export const riskDefinition: ResourceDefinition = {
  title: 'Risk Management Stages',
  nameSingular: 'Risk Management Stage',
  namePlural: 'Risk Management Stages',
  className: 'Risk',
  mainIdentifier: 'name',
  slug: 'taxonomies/risks',
  path: 'risk-management-stages',
  icon: 'icon-grid',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Modifier',
      permission: 'editTaxonomies',
      action: (risk) => ({
        type: ActionType.Link,
        link: {
          path: `${riskDefinition.path}/${risk.id}/edit`
        }
      })
    },
    {
      label: 'Supprimer',
      permission: 'deleteTaxonomies',
      action: (risk) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: risk,
          definition: riskDefinition
        }
      })
    }
  ]
}
